import React, { useState, useEffect } from "react";

import { DatePicker } from "antd";

import { Pie } from "@ant-design/plots";

import REQUESTS from "../../../api/requests";

import numberChanging from "../../../utils/numberChange";

import classes from "../styles/dashboard.module.scss";

export default function PaymentStatistics() {
  const [chartData, setChartData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  // const config = {
  //   appendPadding: 10,
  //   data: chartData,
  //   angleField: "value",
  //   colorField: "type",
  //   radius: 1,
  //   innerRadius: 0.6,
  //   width: 800,
  //   height: 263,
  //   label: {
  //     content: "{value}",
  //     style: {
  //       textAlign: "center",
  //       fontSize: 12,
  //     },
  //   },
  //   meta:{
  //     value:""
  //   },
  //   statistic: {
  //     content: {
  //       offsetY: 4,
  //       style: {
  //         fontSize: "32px",
  //       },
  //       customHtml: (container, view, datum, data) => {
  //         const text = data.reduce((r, d) => r + d.value, 0);
  //         // return parseFloat(text).toFixed(2);
  //         return ""
  //       },
  //     },
  //   },
  //   interactions: [
  //     {
  //       type: "element-selected",
  //     },
  //     {
  //       type: "element-active",
  //     },
  //   ],
  // };
  const config = {
    appendPadding: 10,
    data: chartData,
    angleField: 'value',
    colorField: 'type',
    radius: 0.75,
    height:334,
    label: {
      type: 'spider',
      labelHeight: 28,
      content: '{name}\n{percentage}',
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
  };
  const getChart = () => {
    const query = { year: selectedYear || new Date().getFullYear() };
    function collback(data) {
      const dataStructure = [
        {
          type: "January",
          value: 0,
        },
        {
          type: "February",
          value: 0,
        },
        {
          type: "March",
          value: 456.8888888888888,
        },
        {
          type: "April",
          value: 456.8888888888888,
        },
        {
          type: "May",
          value: 0,
        },
        {
          type: "June",
          value: 0,
        },
        {
          type: "July",
          value: 0,
        },
        {
          type: "August",
          value: 0,
        },
        {
          type: "September",
          value: 0,
        },
        {
          type: "October",
          value: 0,
        },
        {
          type: "November",
          value: 0,
        },
        {
          type: "December",
          value: 0,
        },
      ];

      for (let i = 0; i < data.length; i++) {
        dataStructure[i]["value"] = numberChanging(data[i]);
      }
      setChartData(dataStructure);
    }

    REQUESTS.STATISTICS.CART_PAYMENTS(collback, (err) => {}, query);
  };

  const onChange = (date, dateString) => {
    setSelectedYear(dateString);
  };

  useEffect(() => {
    getChart();
  }, [selectedYear]);

  return (
    <div className={classes["payment-statistics-chart"]}>
      <div className={classes["payment-chart-date"]}>
        <span className={classes["payment-chart-container-title"]}>
          PAYMENT
        </span>
        <DatePicker
          onChange={onChange}
          picker="year"
          placeholder="Select date"
        />
      </div>
      <Pie {...config} className="payment-statistics-pie" />
    </div>
  );
}
