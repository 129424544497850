import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { selectwidgets } from "../../../features/widgets/selector";

import TEXT from "../../../config/text";
import REQUESTS from "../../../api/requests";
import classes from "../style/userPackageListDrawer.module.scss";
import ErrorMessage from "../../../components/ErrorMessage";

import {
  Button,
  Form,
  Input,
  Drawer,
  Select,
  Switch,
  handleTableChange,
  Checkbox,
} from "antd";
const { Option } = Select;
const { TextArea } = Input;

const UserPackageListDrawer = ({
  onClose,
  visible,
  current,
  getData,
  translation,
  followAddElement,
  imaConfigs,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [recommended, setRecommended] = useState(current?.recommended || false);
  const useScrollRef = useRef();
  const useParentRef = useRef();
  const [message, setMessage] = useState({
    type: false,
    text: "",
  });
  const [hasAdsMessage, setHasAdsMessage] = useState({
    type: false,
    text: "",
  });
  const [paypalEnabled, setPaypalEnabled] = useState(false);
  const [stripeEnabled, setStripeEnabled] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [checkedForWeb, setCheckedForWeb] = useState(null);
  const [checkedForReseller, setCheckedForReseller] = useState(null);
  const [checkedAds, setCheckedAds] = useState(null);
  const widgets = useSelector(selectwidgets);

  const onValuesChange = (changedValues, allValues) => {
    setMessage({
      type: false,
      text: "",
    });
  };

  function scrollIntoView() {
    setTimeout(() => {
      useScrollRef.current.scrollIntoView({ block: "end", behavior: "smooth" });
    }, 200);
  }

  const onFinish = (values) => {
    setLoading(true);

    const body = {
      name: values.name,
      schedule: values.schedule,
      price: values.price,
      description: values.description,
      activation_needed: Number(values.activation_needed),
      //   recommended: recommended,
      paypal_price_id: values.paypal_price_id,
      stripe_price_id: values.stripe_price_id,
      for_web: checkedForWeb,
      for_reseller: checkedForReseller,
      has_ads: imaConfigs && imaConfigs.is_enable ? checkedAds : false,
    };

    function callback() {
      if (!current) {
        followAddElement.setCurrentPage(1);
        followAddElement.setSearch("");
        followAddElement.setSort(["id", "DESC"]);
        followAddElement.setCurrentPage(1);
        followAddElement.setLimit(10);
        followAddElement.setTotal();
        followAddElement.setDate("");
      }
      setLoading(false);
      onClose();
      getData();
    }

    function errorCallback(err) {
      setLoading(false);
      setMessage({ type: false, text: err });
    }

    if (current) {
      body.id = current.id;
      REQUESTS.USER_PACKEGES.EDIT(body, callback, errorCallback);
    } else {
      REQUESTS.USER_PACKEGES.ADD(body, callback, errorCallback);
    }
  };

  const getPaymentSettings = () => {
    REQUESTS.PAYMENT_SETTINGS((data) => {
      setPaypalEnabled(data.paypal_enabled);
      setStripeEnabled(data.stripe_enabled);
    });
  };

  const onChange = (value) => {
    setSelectedValue(value);
  };

  const handleChange = (e) => {
    if (e.target.name) {
      if (e.target.name == "for_web") {
        setCheckedForWeb(e.target.checked);
      } else if (e.target.name == "for_reseller") {
        setCheckedForReseller(e.target.checked);
      } else if (e.target.name == "has_ads") {
        setCheckedAds(e.target.checked);
      }
    }
  };
  useEffect(() => {
    if (visible) {
      scrollIntoView();
    } else {
      setLoading(false);
      setRecommended(false);
      onValuesChange();
      setRecommended(false);
    }
  }, [visible]);

  useEffect(() => {
    if (current) {
      setCheckedAds(current.has_ads);
      setCheckedForWeb(current.for_web);
      setCheckedForReseller(current.for_reseller);
      setRecommended(current.recommended);

      form.setFields([
        {
          name: "name",
          value: current.name,
        },

        {
          name: "schedule",
          value: current.schedule,
        },

        {
          name: "price",
          value: current.price,
        },

        {
          name: "activation_needed",
          value: current.activation_needed,
        },

        {
          name: "description",
          value: current.description,
        },

        {
          name: "paypal_price_id",
          value: current.paypal_price_id,
        },

        {
          name: "stripe_price_id",
          value: current.stripe_price_id,
        },
        {
          name: "for_web",
          value: current.for_web,
        },
        {
          name: "for_reseller",
          value: current.for_reseller,
        },
        {
          name: "for_web/res",
          value: current.for_web && current.for_reseller,
        },
      ]);
    } else {
      form.resetFields();
      setCheckedForWeb(false);
      setCheckedForReseller(false);
      setCheckedAds(false);
    }

    if (visible) {
      getPaymentSettings();
    } else {
      setPaypalEnabled(false);
      setStripeEnabled(false);
    }
  }, [current, visible]);

  useEffect(() => {});
  return (
    <div ref={useParentRef}>
      <Drawer
        title={
          current
            ? `${translation["Edit"] || TEXT["Edit"]} ${
                translation["Pricing"] || TEXT["Pricing"]
              }`
            : `${translation["Add"] || TEXT["Add"]} ${
                translation["Pricing"] || TEXT["Pricing"]
              }`
        }
        placement="right"
        onClose={onClose}
        open={visible}
      >
        <div ref={useScrollRef}></div>
        <Form
          form={form}
          name="profile"
          layout="vertical"
          onFinish={onFinish}
          onValuesChange={onValuesChange}
          //   initialValues={{
          //     for_web: current && current.for_web,
          //     for_reseller: current && current.for_reseller,
          //     "for_web/res": current && current.for_web && current.for_reseller,
          //   }}
        >
          <Form.Item
            label={translation["Name"] || TEXT["Name"]}
            name="name"
            rules={[
              {
                required: true,
                message: "Please input name",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={translation["Price"] || TEXT["Price"]}
            name="price"
            rules={[
              {
                required: true,
                message: "Please input price",
              },
            ]}
          >
            <Input type={"number"} min={0} />
          </Form.Item>
          <Form.Item
            label={translation["Schedule"] || TEXT["Schedule"]}
            name="schedule"
            rules={[
              {
                required: true,
                message: "Please input Schedule",
              },
            ]}
          >
            <Select
              className={classes["form_item_select"]}
              defaultValue={current?.schedule}
              onChange={onChange}
            >
              <Option value="month">Monthly</Option>
              <Option value="year">Yearly</Option>
              <Option value="lifetime">Lifetime</Option>
            </Select>
          </Form.Item>

          {/* <Form.Item
          label={translation["Schedule time"] || TEXT["Schedule time"]}
          name="schedule_time"
          rules={[
            {
              required: true,
              message: "Please input schedule time",
            },
          ]}
        >
          <Input type={"number"} />
        </Form.Item> */}

          <Form.Item
            label={
              translation["Activation needed"] || TEXT["Activation needed"]
            }
            name="activation_needed"
            rules={[
              {
                required: true,
                message: "Please input activation!",
              },
            ]}
          >
            <Input type={"number"} min={0} />
          </Form.Item>

          <Form.Item
            label="Paypal price id"
            name="paypal_price_id"
            rules={[
              {
                required: paypalEnabled && selectedValue !== "lifetime",
                message: "Please input paypal price id!",
              },
            ]}
          >
            <Input disabled={selectedValue === "lifetime" ? true : false} />
          </Form.Item>

          <Form.Item
            label="Stripe price id"
            name="stripe_price_id"
            rules={[
              {
                required: stripeEnabled && selectedValue !== "lifetime",
                message: "Please input stripe price id!",
              },
            ]}
          >
            <Input disabled={selectedValue === "lifetime" ? true : false} />
          </Form.Item>
          {/* <Form.Item
                        label={translation["Recommended"] || TEXT["Recommended"]}
                        name="recommended"
                    >
                        <Switch
                            checked={recommended}
                            disabled={current?.recommended}
                            style={{ backgroundColor: recommended ? "#1890ff" : undefined }}
                            onClick={() => {
                                setRecommended(!recommended);
                            }}
                        />
                    </Form.Item> */}
          <Form.Item
            label={translation["Description"] || TEXT["Description"]}
            name="description"
            rules={[
              {
                required: true,
                message: "Please input description!",
              },
            ]}
          >
            <TextArea rows={4} />
          </Form.Item>

          <ErrorMessage message={message} />
          {/* <div>
            <Form.Item name={"for_web"} valuePropName="checked">
              <Checkbox >web</Checkbox>
            </Form.Item>
            <Form.Item name={"for_reseller"} valuePropName="checked">
              <Checkbox>reseller</Checkbox>
            </Form.Item>
            <Form.Item name={"for_web/res"} valuePropName="checked">
              <Checkbox>web/reseller</Checkbox>
            </Form.Item>
          </div> */}
          <div className={classes["checkboxes-container"]}>
            <Checkbox
              name={"for_web"}
              checked={checkedForWeb}
              onChange={handleChange}
            >
              web
            </Checkbox>
            <Checkbox
              name={"for_reseller"}
              checked={checkedForReseller}
              onChange={handleChange}
              style={{ marginLeft: 0 }}
            >
              reseller
            </Checkbox>
            {widgets &&
              widgets.google_ima &&
              widgets.google_ima.enabled &&
              widgets.google_ima.enabled === "true" && (
                <Checkbox
                  name={"has_ads"}
                  checked={
                    imaConfigs && imaConfigs.is_enable ? checkedAds : false
                  }
                  onChange={handleChange}
                  style={{ marginLeft: 0 }}
                >
                  has ads
                </Checkbox>
              )}

            {imaConfigs && !imaConfigs.is_enable && checkedAds && (
              <ErrorMessage
                message={{
                  type: false,
                  text: "Turn on Google IMA from settings to use it",
                }}
              />
            )}
          </div>

          <div className={classes["save_button_div"]}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className={classes["save_button"]}
                loading={loading}
              >
                {translation["Save"] || TEXT["Save"]}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Drawer>
    </div>
  );
};

export default UserPackageListDrawer;
