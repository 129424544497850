import { useEffect, useState } from "react";
import { Table, Button, Modal, DatePicker, Input ,Popover} from "antd";
import TEXT from "../../../config/text";
import classes from '../style/devicePlaylists.module.scss'
import Createplaylist from "./CreatePlaylistDrawer";
import ActionButton from "../../../components/ActionButton";
import REQUESTS from "../../../api/requests";
import showPropsConfirm from "../../../components/showPropsConfirm";
import {
  PlusOutlined,
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import getMyDate from "../../../components/getMyDate";
import { selectTranslation } from "../../../features/Translation/TranslationSlice";
import TableButtons from "../../../components/TableButtons";
import { useSelector } from "react-redux";
const { RangePicker } = DatePicker;


function DevicePlaylists({userInfo}) {


  const [total, setTotal] = useState();
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [ediPackage, setEdiPackage] = useState(null);
  const [deviceUserList, setDeviceUserList] = useState([]);
 
 const translation = useSelector(selectTranslation)

  const deleteTable = (id) => {
    const callback = (data) => {
     getDeviceUserList()
    };

    const errorCallback = (data) => {};

    REQUESTS.DEVICE.DELETE(id, callback, errorCallback);
  };

 
  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "delete":
        showPropsConfirm(item.id,deleteTable,loading,`${translation["DELETE"] || TEXT["DELETE"]}`,"#bf4342")
        break;

      case "edit":
        setEdiPackage(item);
        setVisible(true);
        break;

      default:
        break;
    }
  };

 

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      // key:"id",
      // sorter: (a, b) => a.value - b.value,
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: `${translation["Name"] || TEXT["Name"]}`,
      dataIndex: "name",
      render: (record, text, index) => {
          return `${record}` ;
       
      },
    },

    // {
    //   title:`${translation["Device id"] || TEXT["Device id"]}`,
    //   dataIndex: "deviceId",
    //   key: "deviceId",
    //   render: (record, text, index) => {
    //     return `${record}` ;
     
    // },
      
    // },
    {
      title:`${translation["Url"] || TEXT["Url"]}`,
      dataIndex: "url",
      render: (record, text, index) => {
        if (record) {
        const content = (
            <div>
                {record}
            </div>
        );
        return (
            <Popover content={content}>
                <div className={classes['ref-link']}>
                    <a href={record} target={'_blank'}
                    style={{color:"#1890ff"}}
                    >{record}</a>
                </div>
            </Popover>
        );
    }}
    },

    {
      title:`${translation["Date"] || TEXT["Date"]}`,
      dataIndex: "createdAt",
      render: (record, text, index) => {
        return record?getMyDate(record):"N/A"
      },
      
    },


    {
      title: "",
      dataIndex: "action",
      align: "center",
      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          buttons={[
            {
              key: "edit",
              text: `${translation["Edit"] || TEXT["Edit"]}`,
              icon: <EditOutlined />,
            },
            {
              key: "delete",
              text: `${translation["Delete"] || TEXT["Delete"]}`,
              icon: <DeleteOutlined />,
            },
          ]}
        />
      ),
    },
   
  ];

  const getDeviceUserList = () => {
    setLoading(true);
    REQUESTS.DEVICE.GET_DEVICE(userInfo?.id, {sort:JSON.stringify(["id","DESC"])},(data) => {
      setTotal(data.count);
      setLoading(false);
      setDeviceUserList(data.playlists.reverse());
    });
  };


  const handleTableChange = (pagination, filters, sorter) => {
   
    

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  const onClickAdd = () => {
    setVisible(true);
  };

  const closeDrawer = () => {
    setVisible(false);
    setEdiPackage(null);
  };

  useEffect(() => {

    
      getDeviceUserList();
      
   
  }, [userInfo]);

  return (
    <div className={classes["playlists_list_table"]}>
      <div className={classes["playlists_list_title"]}>
        <p className={classes["playlists_title-text"]}>Playlists</p>
        <Button type="primary" icon={<PlusOutlined />} onClick={onClickAdd} />
      </div>

      <Table
        loading={loading}
        rowKey="id"
        columns={columns}
        dataSource={deviceUserList}
        onChange={handleTableChange}
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          total: total,
          pageSize: limit,
          // showSizeChanger: true,
        }}
        scroll={{ x: "max-content" }}
        size="small"
      />

      <Createplaylist
        visible={visible}
        userInfo={userInfo}
        onClose={closeDrawer}
        current={ediPackage}
        getData={getDeviceUserList}
        translation={translation}
      />
    </div>
  );
}

export default DevicePlaylists;