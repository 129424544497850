import { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { selectappInfo } from "../../features/appInfoSlice/AppInfoSlice";

import LoginForm from "./components/LoginForm";
import ForgotPasswordForm from "./components/ForgotPasswordForm";

import classes from "./index.module.scss";

function Login() {
    const appInfo = useSelector(selectappInfo);

    const [active, setActive] = useState(true);

    const onClickForgotPassword = (event) => {
        event.preventDefault();
        setActive(!active);
    };

    return (
        <div
            className={classes["logoin_block"]}
            style={{ backgroundImage: ` url(${appInfo?.admin_login_background} )` }}
        >
            <div className={classes["logoin_container"]}>
                <div className={classes["admin_welcome_div"]}></div>
                <div className={classes["login_div_container"]}>
                    <div className={classes["login_div"]}>
                        <img
                            loading="lazy"
                            className={classes["login_img"]}
                            // src="assets/img/pleyerLogo.jpeg"
                            src={appInfo?.logo}
                            alt=""
                        />
                        {active ? (
                            <LoginForm onClickForgotPassword={onClickForgotPassword} />
                        ) : (
                            <ForgotPasswordForm onClickForgotPassword={onClickForgotPassword} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
