import { useState, useEffect } from "react";
import TEXT from "../../../config/text";
import REQUESTS from "../../../api/requests";
import country from "../../../country.json";
import ErrorMessage from "../../../components/ErrorMessage";
import { Button, Form, Input, Checkbox, Drawer, Select } from "antd";
import {
  LockOutlined,
  UserAddOutlined,
  MailOutlined,
  SafetyCertificateOutlined,
  PhoneOutlined,
  BarcodeOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import classes from "../style/reselerProfile.module.scss";
const { Option } = Select;

const ResRefDrawer = ({
  onClose,
  visible,
  current,
  getData,
  translation,
  followAddElement,
  type="",
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [referral, setReferral] = useState(current?.is_referral || false);
  const [reseller, setReseller] = useState(current?.is_reseller || false);
  const [message, setMessage] = useState({
    type: false,
    text: "",
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const onValuesChange = (e) => {
    setMessage({
      type: false,
      text: "",
    });
  };

  useEffect(() => {
    if (visible === true && current) {
      setReferral(current?.is_referral);
      setReseller(current?.is_reseller);
    }
  }, [visible]);

  useEffect(() => {
    if (visible === false) {
      setLoading(false);
      onValuesChange();
      setReseller(true);
      setReferral(true);
    }
  }, [visible]);

  const onFinish = (values) => {
    setLoading(true);

    const body = {
      name: values.name,
      surname: values.surname,
      email: values.email,
      phone: values.phone,
      company: values.company,
      address: values.address,
      country: values.country,
      is_referral: referral,
      is_reseller: reseller,
    };

    if (values.password.length) {
      body.password = values.password;
    }

    function callback() {
      if (!current) {
        followAddElement.setCurrentPage(1);
        followAddElement.setSearch("");
        followAddElement.setSort(["id", "DESC"]);
        followAddElement.setCurrentPage(1);
        followAddElement.setLimit(10);
        followAddElement.setTotal();
      }

      setLoading(false);
      onClose();
      getData();
    }

    function errorCallback(err) {
      setLoading(false);
      setMessage({ type: false, text: err });
    }

    if (referral === false && reseller === false) {
      setLoading(false);
      setMessage({ type: false, text: "Please select referral or reseller" });
    } else {
      if (current) {
        body.id = current.id;
        REQUESTS.REFERRAL_RESELLER.EDIT(body, callback, errorCallback);
      } else {
        REQUESTS.REFERRAL_RESELLER.ADD(body, callback, errorCallback);
      }
    }
  };

  useEffect(() => {
    if (current) {
      form.setFields([
        {
          name: "name",
          value: current.name,
        },

        {
          name: "surname",
          value: current.surname,
        },

        {
          name: "company",
          value: current.company,
        },

        {
          name: "country",
          value: current.country,
        },

        {
          name: "phone",
          value: current.phone,
        },

        {
          name: "email",
          value: current.email,
        },

        {
          name: "address",
          value: current.address,
        },

        {
          name: "password",
          value: "",
        },
      ]);
    } else {
      form.resetFields();
    }
  }, [current, visible]);

  useEffect(() => {
    const handleChangeWindowInnerWidth = () =>
      setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleChangeWindowInnerWidth);
    return () =>
      window.removeEventListener("resize", handleChangeWindowInnerWidth);
  }, []);

  return (
    <div className={classes["referral_rReseller"]}>
      <Drawer
        title={
          current
            ? `${translation["Edit"] || TEXT["Edit"]} 
            ${
              type === "referral"
                ? translation["Referral"] || TEXT["Referral"]
                : translation["Reseller"] || TEXT["Reseller"]
            }`
            : `${translation["Add"] || TEXT["Add"]}
            ${
              type === "referral"
                ? translation["Referral"] || TEXT["Referral"]
                : translation["Reseller"] || TEXT["Reseller"]
            }`
        }
        placement="right"
        onClose={onClose}
        visible={visible}
        width={windowWidth > 623 ? 560 : "91vw"}
      >
        <Form
          className={classes["referral_rReseller_form"]}
          form={form}
          name="profile"
          layout="vertical"
          onFinish={onFinish}
          onValuesChange={onValuesChange}
          initialValues={{
            password: "",
          }}
        >
          <div className={classes["form_input_div"]}>
            <Form.Item
              label={translation["Name"] || TEXT["Name"]}
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input your Name!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="surname"
              label={translation["Surname"] || TEXT["Surname"]}
              rules={[
                {
                  required: true,
                  message: "Please input your surname!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className={classes["form_input_div"]}>
            <Form.Item
              name="phone"
              label={translation["Phone"] || TEXT["Phone"]}
              rules={[
                {
                  required: true,
                  message: "Please input your Phone!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="email"
              label={translation["Email"] || TEXT["Email"]}
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className={classes["form_input_div"]}>
            <Form.Item
              name="company"
              label={translation["Company"] || TEXT["Company"]}
              rules={[
                {
                  required: true,
                  message: "Please input your Company!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="address"
              label={translation["Address"] || TEXT["Address"]}
              rules={[
                {
                  required: true,
                  message: "Please input your address!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className={classes["form_input_div_country"]}>
            <Form.Item
              className={classes["form_item_country"]}
              name="country"
              label={translation["Country"] || TEXT["Country"]}
              rules={[
                {
                  required: true,
                  message: "Please input your country!",
                },
              ]}
            >
              <Select showSearch={true} className={classes["select"]}>
                {country.map((item) => {
                  return (
                    <Option key={item.code} value={`${item.name}`}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              name="password"
              label={translation["Password"] || TEXT["Password"]}
            >
              <Input.Password className={classes["password_input"]} />
            </Form.Item>
          </div>

          <div className={classes["form_checkbox_div"]}>
                <Checkbox
                  className={classes["checkbox-form"]}
                  checked={referral}
                  onClick={() => {
                    setReferral(!referral);
                  }}
                >
                  {translation["Referral"] || TEXT["Referral"]}
                </Checkbox>
             
                <Checkbox
                  className={classes["checkbox-form_midele"]}
                  onClick={() => {
                    setReseller(!reseller);
                  }}
                  checked={reseller}
                >
                  {translation["Reseller"] || TEXT["Reseller"]}
                </Checkbox>
            
          </div>

          <ErrorMessage message={message} />
          <div className={classes["save_button_div"]}>
            <Form.Item>
              <Button
                className={classes["save_button"]}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {translation["Save"] || TEXT["Save"]}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Drawer>
    </div>
  );
};

export default ResRefDrawer;
