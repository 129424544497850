import { useState, useEffect } from "react";

import { useNavigate, createSearchParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import {
  Button,
  Form,
  Input,
  Upload,
  Select,
  Image,
  notification,
  Switch,
} from "antd";

import { selectTranslation } from "../../../features/Translation/TranslationSlice";
import { selectwidgets } from "../../../features/widgets/selector";

import { editAppInfo } from "../../../features/appInfoSlice/AppInfoSlice";
import REQUESTS from "../../../api/requests";
import TEXT from "../../../config/text";

import classes from "../style/branding.module.scss";

const { Option } = Select;

const Branding = ({ host }) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingImg, setLoadingImg] = useState(false);
  const [application, setApplication] = useState(null);
  const [currencies, setCurrencies] = useState();
  const [paypal, setPaypal] = useState(false);
  const [stripe, setStripe] = useState(false);
  const [message, setMessage] = useState({
    type: false,
    text: "application update",
  });
  const [fileList, setFileList] = useState([]);
  const [fileListBackimg, setFileListBackimg] = useState([]);
  const [favicon, setFavicon] = useState([]);
  const [url, setUrl] = useState();
  const [urlBack, setUrlBack] = useState();
  const [urlFavicon, setUrlFavicon] = useState();

  const [underConstruction, setUnderConstruction] = useState(false);
  const [resellerLoginBackground, setResellerLoginBackground] = useState([]);
  const [urlResellerLoginBackground, setUrlResellerLoginBackground] =
    useState("");

  const [adminLoginBackground, setAdminLoginBackground] = useState([]);
  const [urlAdminLoginBackground, setUrlAdminLoginBackground] = useState("");

  const translation = useSelector(selectTranslation);
  const widgets = useSelector(selectwidgets);

  const navigate = useNavigate();
  const goToPosts = (params) =>
    navigate({
      search: `?${createSearchParams(params)}`,
    });

  useEffect(() => {
    goToPosts({ page: host });
  }, [host]);

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      const myurl = window.URL
        ? window.URL.createObjectURL(file)
        : window.webkitURL.createObjectURL(file);
      setUrl(myurl);
      if (file.type !== "image/jpeg" && file.type !== "image/png") {
        setFileList([]);
        setUrl(null);
        openNotification("You can only upload JPG/PNG file!");
      }
      return false;
    },

    fileList,
  };

  const propsBack = {
    onRemove: (file) => {
      const index = fileListBackimg.indexOf(file);
      const newFileList = fileListBackimg.slice();
      newFileList.splice(index, 1);
      setFileListBackimg(newFileList);
    },
    beforeUpload: (file) => {
      // setFileListBackimg([...fileList, file]);
      setFileListBackimg([file]);
      const myurl = window.URL
        ? window.URL.createObjectURL(file)
        : window.webkitURL.createObjectURL(file);
      setUrlBack(myurl);
      if (file.type !== "image/jpeg" && file.type !== "image/png") {
        setFileListBackimg([]);
        setUrlBack(null);
        openNotification("You can only upload JPG/PNG file!");
      }

      return false;
    },
    fileListBackimg,
  };

  const propsFavicon = {
    onRemove: (file) => {
      const index = favicon.indexOf(file);
      const newFileList = favicon.slice();
      newFileList.splice(index, 1);
      setFavicon(newFileList);
    },
    beforeUpload: (file) => {
      // setFavicon([...fileList, file]);
      setFavicon([file]);
      const myurl = window.URL
        ? window.URL.createObjectURL(file)
        : window.webkitURL.createObjectURL(file);
      setUrlFavicon(myurl);
      if (file.type !== "image/jpeg" && file.type !== "image/png") {
        setFavicon([]);
        setUrlFavicon(null);
        openNotification("You can only upload JPG/PNG file!");
      }
      return false;
    },
    favicon,
  };

  const propsresellerLoginBackground = {
    onRemove: (file) => {
      const index = resellerLoginBackground.indexOf(file);
      const newFileList = resellerLoginBackground.slice();
      newFileList.splice(index, 1);
      setResellerLoginBackground(newFileList);
    },
    beforeUpload: (file) => {
      // setFavicon([...fileList, file]);
      setResellerLoginBackground([file]);
      const myurl = window.URL
        ? window.URL.createObjectURL(file)
        : window.webkitURL.createObjectURL(file);
      setUrlResellerLoginBackground(myurl);
      if (file.type !== "image/jpeg" && file.type !== "image/png") {
        setResellerLoginBackground([]);
        setUrlResellerLoginBackground(null);
        openNotification("You can only upload JPG/PNG file!");
      }
      return false;
    },
    resellerLoginBackground,
  };

  const propsAdminLoginBackground = {
    onRemove: (file) => {
      const index = adminLoginBackground.indexOf(file);
      const newFileList = adminLoginBackground.slice();
      newFileList.splice(index, 1);
      setAdminLoginBackground(newFileList);
    },
    beforeUpload: (file) => {
      // setFavicon([...fileList, file]);
      setAdminLoginBackground([file]);
      const myurl = window.URL
        ? window.URL.createObjectURL(file)
        : window.webkitURL.createObjectURL(file);
      setUrlAdminLoginBackground(myurl);
      if (file.type !== "image/jpeg" && file.type !== "image/png") {
        setAdminLoginBackground([]);
        setUrlAdminLoginBackground(null);
        openNotification("You can only upload JPG/PNG file!");
      }
      return false;
    },
    resellerLoginBackground,
  };

  const openNotification = (message) => {
    const args = {
      message: "application",
      description: message,
      duration: 5,
    };
    notification.open(args);
  };

  const getApplication = () => {
    const collback = (data) => {
      dispatch(editAppInfo(data));
      setApplication(data);
      setUnderConstruction(data.under_construction);
      setPaypal(data.paypal_enabled);
      setStripe(data.stripe_enabled);
    };

    REQUESTS.APP_INFO.GET(collback);
  };

  const getcurrencies = () => {
    const collback = (data) => {
      setCurrencies(data);
    };

    REQUESTS.APP_INFO.CURRENCIES(collback);
  };

  useEffect(() => {
    getApplication();
    getcurrencies();
  }, []);

  const onValuesChange = (e) => {
    setMessage({
      type: false,
      text: "",
    });
  };

  const onFinish = (values) => {
    setLoading(true);
    const formData = new FormData();

    formData.append("id", application?.id);
    formData.append("header_text", values.header_text);
    formData.append("text", values.text);
    if (values.accept_text.length) {
      formData.append("accept_text", values.accept_text);
    }

    formData.append("under_construction", underConstruction);

    if (fileList) {
      fileList.forEach((file) => {
        formData.append("logo", file);
      });
      if (fileListBackimg) {
        fileListBackimg.forEach((newfile) => {
          formData.append("background_image", newfile);
        });
      }

      if (favicon) {
        favicon.forEach((newfile) => {
          formData.append("favicon", newfile);
        });
      }

      if (resellerLoginBackground) {
        resellerLoginBackground.forEach((newfile) => {
          formData.append("reseller_login_background", newfile);
        });
      }

      if (adminLoginBackground) {
        adminLoginBackground.forEach((newfile) => {
          formData.append("admin_login_background", newfile);
        });
      }
    }

    function callback(data) {
      setLoading(false);
      setFileList([]);
      setFileListBackimg([]);
      setFavicon([]);
      setAdminLoginBackground([]);
      setResellerLoginBackground([]);
      getApplication();
      openNotification("application update");
    }

    function errorCallback(err) {
      setLoading(false);
      setMessage((prev) => {
        return {
          ...prev,
          text: err,
          type: false,
        };
      });
      openNotification();
    }

    REQUESTS.APP_INFO.UPDATE(formData, callback, errorCallback);
  };
  useEffect(() => {
    form.setFields([
      {
        name: "header_text",
        value: application?.header_text,
      },

      {
        name: "text",
        value: application?.text,
      },

      {
        name: "accept_text",
        value: application?.accept_text,
      },
    ]);
  }, [application]);

  return (
    <Form
      form={form}
      name="profile"
      layout="vertical"
      onFinish={onFinish}
      onValuesChange={onValuesChange}
    >
      <div className={classes["app_info"]}>
        <div className="app_info_img">
          <div className="settings__branding-section__form-item">
            <Form.Item
              label={
                translation["Application backdrop"] ||
                TEXT["Application backdrop"]
              }
            >
              <div className="app_upload_form">
                <Image
                  className="upload_img"
                  src={url ? url : application?.logo}
                />
                <Upload {...props} fileList={fileList}>
                  <Button className={classes["uload_button"]} type="primary">
                    Upload
                  </Button>
                </Upload>
              </div>
            </Form.Item>
          </div>

          <div className="settings__branding-section__form-item">
            <Form.Item
              label={
                translation["Application background image"] ||
                TEXT["Application background image"]
              }
            >
              <div className="app_upload_form">
                <Image
                  className="upload_img"
                  src={urlBack ? urlBack : application?.background_image}
                />
                <Upload {...propsBack} fileList={fileListBackimg}>
                  <Button className={classes["uload_button"]} type="primary">
                    Upload
                  </Button>
                </Upload>
              </div>
            </Form.Item>
          </div>
          <div className="settings__branding-section__form-item">
            <Form.Item
              label={
                translation["Application Favicon"] ||
                TEXT["Application Favicon"]
              }
            >
              <div className="app_upload_form">
                <Image
                  className="upload_img"
                  src={urlFavicon ? urlFavicon : application?.favicon}
                />

                <Upload {...propsFavicon} fileList={favicon}>
                  <Button className={classes["uload_button"]} type="primary">
                    Upload
                  </Button>
                </Upload>
              </div>
            </Form.Item>
          </div>
          <div className="settings__branding-section__form-item">
            <Form.Item
              label={
                translation["Reseller login background"] ||
                TEXT["Reseller login background"]
              }
            >
              <div className="app_upload_form">
                <Image
                  className="upload_img"
                  src={
                    urlResellerLoginBackground
                      ? urlResellerLoginBackground
                      : application?.reseller_login_background
                  }
                />

                <Upload
                  {...propsresellerLoginBackground}
                  fileList={resellerLoginBackground}
                >
                  <Button className={classes["uload_button"]} type="primary">
                    Upload
                  </Button>
                </Upload>
              </div>
            </Form.Item>
          </div>

          <div className="settings__branding-section__form-item">
            <Form.Item
              label={
                translation["Admin login background"] ||
                TEXT["Admin login background"]
              }
            >
              <div className="app_upload_form">
                <Image
                  className="upload_img"
                  src={
                    urlAdminLoginBackground
                      ? urlAdminLoginBackground
                      : application?.admin_login_background
                  }
                />

                <Upload
                  {...propsAdminLoginBackground}
                  fileList={adminLoginBackground}
                >
                  <Button className={classes["uload_button"]} type="primary">
                    Upload
                  </Button>
                </Upload>
              </div>
            </Form.Item>
          </div>
        </div>
        <div className="branding__text-form">
          <Form.Item
            className={classes["ant-form-item-required"]}
            label={translation["Header text"] || TEXT["Header text"]}
            name="header_text"
            rules={[
              {
                required: true,
                message: "Please input header text",
              },
            ]}
          >
            <Input className={classes["ant_input"]} />
          </Form.Item>

          <Form.Item
            name="text"
            label={
              translation["Enter activation text"] ||
              TEXT["Enter activation text"]
            }
          >
            <Input className={classes["ant_input"]} />
          </Form.Item>

          <Form.Item
            name="accept_text"
            label={
              translation["Enter accept text"] || TEXT["Enter accept text"]
            }
          >
            <Input.TextArea
              className={classes["ant_input"]}
              style={{
                resize: "none",
              }}
              rows={15}
            />
          </Form.Item>
          {widgets?.editor?.enabled === "true" ? (
            <Form.Item>
              <a
                target={"_blank"}
                className={classes["advanced_settings_link"]}
                href={`${
                  application?.web_host
                }/#/home?token=${localStorage.getItem("TOKEN")}`}
              >
                {translation["Advanced web settings"] ||
                  TEXT["Advanced web settings"]}
              </a>
            </Form.Item>
          ) : (
            ""
          )}
          <Form.Item>
            Under construction page{" "}
            <Switch
              checked={underConstruction}
              onChange={(value) => {
                setUnderConstruction(value);
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={classes["branding_save"]}
              loading={loading}
            >
              {translation["Save"] || TEXT["Save"]}
            </Button>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};

export default Branding;
