import { useState, useEffect } from "react";

import TEXT from "../../../config/text";
import REQUESTS from "../../../api/requests";
import classes from "../style/smtpconfig.module.scss";
import { useNavigate, createSearchParams } from "react-router-dom";
import { Button, Form, Input, notification, Select, Switch } from "antd";
import { useSelector } from "react-redux";
import { selectTranslation } from "../../../features/Translation/TranslationSlice";

export default function PaymentSettings() {
  const navigate = useNavigate();

  const translation = useSelector(selectTranslation);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [currencies, setCurrencies] = useState([]);

  const [paypalEnabled, setPaypalEnabled] = useState(false);
  const [stripeEnabled, setStripeEnabled] = useState(false);
  const [cmiEnabled, setCmiEnabled] = useState(false);

  const getCurrencies = () => {
    const collback = (data) => {
      setCurrencies(data.rows);
    };

    REQUESTS.APP_INFO.CURRENCIES({ limit: 120 }, collback);
  };

  const getPaymentSettings = () => {
    REQUESTS.PAYMENT_SETTINGS((data) => {
     
      form.setFields([
        {
          name: "currency",
          value: data.currency,
        },

        {
          name: "paypal_enabled",
          value: data.paypal_enabled,
        },

        {
          name: "paypal_client_id",
          value: data.paypal_client_id,
        },

        {
          name: "paypal_client_secret",
          value: data.paypal_client_secret,
        },

        {
          name: "stripe_enabled",
          value: data.stripe_enabled,
        },

        {
          name: "stripe_public_key",
          value: data.stripe_public_key,
        },

        {
          name: "stripe_secret_key",
          value: data.stripe_secret_key,
        },

        {
          name: "stripe_webhook_secret",
          value: data.stripe_webhook_secret,
        },

        {
          name: "cmi_enabled",
          value: data.cmi_enabled,
        },

        {
          name: "geteway_url",
          value: data.geteway_url,
        },

        {
          name: "cmi_client_id",
          value: data.cmi_client_id,
        },

        {
          name: "cmi_store_key",
          value: data.cmi_store_key,
        },
      ]);

      setPaypalEnabled(data.paypal_enabled);
      setStripeEnabled(data.stripe_enabled);
      setCmiEnabled(data.cmi_enabled);
    });
  };

  const onFinish = (values) => {
    setLoading(true);

    function callback() {
      getPaymentSettings();

      notification.success({
        description: "Success",
      });

      setLoading(false);
    }

    function errorCallback(err) {
      if (typeof err === "string") {
        notification.error({
          description: err,
        });
      }

      setLoading(false);
    }

    const body = {
      currency: values.currency,
      paypal_enabled: values.paypal_enabled,
      paypal_client_id: values.paypal_client_id || "",
      paypal_client_secret: values.paypal_client_secret || "",
      stripe_enabled: values.stripe_enabled,
      stripe_public_key: values.stripe_public_key || "",
      stripe_secret_key: values.stripe_secret_key || "",
      stripe_webhook_secret: values.stripe_webhook_secret || "",
      cmi_enabled: values.cmi_enabled,
      cmi_client_id: values.cmi_client_id || "",
      cmi_store_key: values.cmi_store_key || "",
      geteway_url:values.geteway_url || ""
    };

    // body = body.filter((el) => el !== "");

    REQUESTS.EDIT_PAYMENT_SETTINGS(body, callback, errorCallback);
  };

  const onValuesChange = (e, all) => {
    setPaypalEnabled(all.paypal_enabled);
    setStripeEnabled(all.stripe_enabled);
    setCmiEnabled(all.cmi_enabled);
  };

  useEffect(() => {
    getCurrencies();
    getPaymentSettings();

    navigate({
      search: `?${createSearchParams({ page: "paymentSettings" })}`,
    });
  }, []);

  return (
    <Form
      form={form}
      name="payments-config"
      layout="vertical"
      onFinish={onFinish}
      // style={{ width: 300 }}
      onValuesChange={onValuesChange}
      className="payment-settings-tab-form"
    >
      <Form.Item
        name="paypal_enabled"
        valuePropName="checked"
        label={translation["Paypal enabled"] || TEXT["Paypal enabled"]}
      >
        <Switch />
      </Form.Item>

      <Form.Item
        name="paypal_client_id"
        label={translation["Paypal client id"] || TEXT["Paypal client id"]}
        rules={[
          {
            required: paypalEnabled,
            message: "Please enter your paypal client id",
          },
        ]}
      >
        <Input disabled={!paypalEnabled} />
      </Form.Item>

      <Form.Item
        name="paypal_client_secret"
        label={
          translation["Paypal client secret"] || TEXT["Paypal client secret"]
        }
        rules={[
          {
            required: paypalEnabled,
            message: "Please enter your paypal client secret",
          },
        ]}
      >
        <Input disabled={!paypalEnabled} />
      </Form.Item>

      <Form.Item
        name="stripe_enabled"
        valuePropName="checked"
        label={translation["Stripe enabled"] || TEXT["Stripe enabled"]}
      >
        <Switch />
      </Form.Item>

      <Form.Item
        name="stripe_public_key"
        label={translation["Stripe public key"] || TEXT["Stripe public key"]}
        rules={[
          {
            required: stripeEnabled,
            message: "Please enter your stripe public key",
          },
        ]}
      >
        <Input disabled={!stripeEnabled} />
      </Form.Item>

      <Form.Item
        name="stripe_secret_key"
        label={translation["Stripe secret key"] || TEXT["Stripe secret key"]}
        rules={[
          {
            required: stripeEnabled,
            message: "Please enter your stripe secret key",
          },
        ]}
      >
        <Input disabled={!stripeEnabled} />
      </Form.Item>

      <Form.Item
        name="stripe_webhook_secret"
        rules={[
          {
            required: stripeEnabled,
            message: "Please enter your stripe webhook secret",
          },
        ]}
        label={
          translation["Stripe webhook secret"] || TEXT["Stripe webhook secret"]
        }
      >
        <Input disabled={!stripeEnabled} />
      </Form.Item>

      <Form.Item
        name="cmi_enabled"
        valuePropName="checked"
        label={translation["CMI enabled"] || TEXT["CMI enabled"]}
      >
        <Switch />
      </Form.Item>
      <Form.Item
        name="geteway_url"
        label={translation["Gateway url"] || TEXT["Gateway url"]}
        rules={[
          {
            required: cmiEnabled,
            message: "Please enter getway url",
          },
        ]}
      >
        <Input disabled={!cmiEnabled} />
      </Form.Item>
      <Form.Item
        name="cmi_client_id"
        label={translation["CMI client id"] || TEXT["CMI client id"]}
        rules={[
          {
            required: cmiEnabled,
            message: "Please enter your CMI client id",
          },
        ]}
      >
        <Input disabled={!cmiEnabled} />
      </Form.Item>

      <Form.Item
        name="cmi_store_key"
        label={translation["CMI store key"] || TEXT["CMI store key"]}
        rules={[
          {
            required: cmiEnabled,
            message: "Please enter your CMI store key",
          },
        ]}
      >
        <Input disabled={!cmiEnabled} />
      </Form.Item>

      <Form.Item
        name="currency"
        label={translation["Currency"] || TEXT["Currency"]}
        rules={[
          {
            required: cmiEnabled,
            message: "Please select currency",
          },
        ]}
      >
        <Select showSearch={true}>
          {currencies.map((item) => {
            return (
              <Select.Option key={item.code} value={`${item.code}`}>
                {item.code}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          onClick={() => form.submit()}
          loading={loading}
          block
        >
          {translation["Save"] || TEXT["Save"]}
        </Button>
      </Form.Item>
    </Form>
  );
}
