import { useSelector } from "react-redux";
import { selectwidgets } from "../../features/widgets/selector";

import RenderTotalsCards from "./components/RenderTotalsCard";
import MostUsedDevices from "./components/MostUsedDevices";
import UsedDevicesProgres from "./components/UsedDevicesProgres";
import PaymentStatistics from "./components/PaymentStatistics";
import ServerStatistics from "./components/ServerStatistics";
import LivesStatisticsChart from "./components/LivesStatisticsChart";
import MoviesStatisticsChart from "./components/MoviesStatisticsChart";
import SeriesStatisticsChart from "./components/SeriesStatisticsChart";

import classes from "./index.module.scss";
import "./styles/chartStyle.scss";

function Dashboard() {
  const widgets = useSelector(selectwidgets);

  return (
    <div>
      <RenderTotalsCards />
      <div className={classes["dashboard-page"]}>
        <div className={classes["dashboard-page__used-devices-progres"]}>
          <UsedDevicesProgres />
          <PaymentStatistics />
        </div>
        <div className={classes["dashboard-page__most-used-devices"]}>
          <MostUsedDevices />
          <ServerStatistics />
        </div>
      </div>

      {widgets &&
        widgets.most_watched_statistics &&
        widgets.most_watched_statistics.enabled &&
        widgets.most_watched_statistics.enabled === "false" && (
          <>
            <div className="pro-widget-warning-text" style={{ marginTop: 30 }}>
              To use this feature you need to upgrade to Pro Version
              <br />
              Please contact us for more details.
            </div>
          </>
        )}

      <LivesStatisticsChart />
      <div className={classes["dashboard-page__charts"]}>
        <MoviesStatisticsChart />
        <SeriesStatisticsChart />
      </div>
    </div>
  );
}

export default Dashboard;
