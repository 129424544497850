import { useState, useEffect } from "react";
import TEXT from "../../../config/text";
import REQUESTS from "../../../api/requests";
import ErrorMessage from "../../../components/ErrorMessage";
import { useSelector } from "react-redux";
import { selectTranslation } from "../../../features/Translation/TranslationSlice";
import { Button, Form, Input, Drawer, Select, notification } from "antd";
import classes from "../style/languagesDraver.module.scss";
const { Option } = Select;
const { TextArea } = Input;

const AddTranslation = ({
  getTranslations,
  translationClose,
  translationVisible,
  current,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    type: false,
    text: "",
  });

  const translation = useSelector(selectTranslation);
  const onValuesChange = (e) => {
    setMessage({
      type: false,
      text: "",
    });
  };

  // const openNotification = (data) => {
  //   const args = {
  //     message: "Word",
  //     description: data,
  //     duration: 5,
  //   };
  //   notification.open(args);
  // };

  useEffect(() => {
    if (translationVisible === false) {
      setLoading(false);
      onValuesChange();
    }
  }, [translationVisible]);

  const onFinish = (values) => {
    setLoading(true);

    const body = {
      //   key: values.key,
      value: values.value,
    };

    function callback() {
      setLoading(false);
      getTranslations();
      translationClose();
    }

    function errorCallback(err) {
      setLoading(false);
      setMessage({ type: false, text: err });
    }

    if (current) {
      body.id = current.id;
      REQUESTS.LENG.TRANSLATIONS_PUT(body, callback, errorCallback);
    } else {
      REQUESTS.LENG.ADD(body, callback, errorCallback);
    }
  };

  useEffect(() => {
    if (current) {
      form.setFields([
        {
          name: "key",
          value: current.key,
        },

        {
          name: "value",
          value: current.value,
        },
      ]);
    } else {
      form.resetFields();
    }
  }, [current, translationVisible]);

  return (
    <Drawer onClose={translationClose} visible={translationVisible}>
      <div>
        <Form
          form={form}
          name="profile"
          layout="vertical"
          onFinish={onFinish}
          onValuesChange={onValuesChange}
          // style={{height:"70px"}}
        >
          <Form.Item
            label={translation["Key"] || TEXT["Key"]}
            name="key"
            rules={[
              {
                required: true,
                message: "Please input your Key!",
              },
            ]}
          >
            <Input disabled="true" />
          </Form.Item>

          <Form.Item
            label={translation["Value"] || TEXT["Value"]}
            name="value"
            rules={[
              {
                required: true,
                message: "Please input your Value!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <ErrorMessage message={message} />

          <div className={classes["save_button_div"]}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className={classes["save_button"]}
                loading={loading}
              >
                {translation["Save"] || TEXT["Save"]}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

export default AddTranslation;
