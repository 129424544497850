import CONSTANTS from "../config";

const URLS = {
    login: `${CONSTANTS.API_HOST}/admin/login`,
    admin: `${CONSTANTS.API_HOST}/admin/profile`,
    get_totals: `${CONSTANTS.API_HOST}/admin/total_info`,
    online_clients: `${CONSTANTS.API_HOST}/admin/online_clients`,
    admin_server: `${CONSTANTS.API_HOST}/admin/server`,
    used_devices: `${CONSTANTS.API_HOST}/admin/most_used_devices`,
    chart: `${CONSTANTS.API_HOST}/admin/chart`,
    admin_devices: `${CONSTANTS.API_HOST}/admin/devices`,
    admin_languages: `${CONSTANTS.API_HOST}/admin/languages`,
    admin_translations: `${CONSTANTS.API_HOST}/admin/translations`,
    admin_reseller: `${CONSTANTS.API_HOST}/admin/reseller`,

    DISABLE: `${CONSTANTS.API_HOST}/admin/disable`,

    activation_package: `${CONSTANTS.API_HOST}/admin/activation_package`,
    referral_status: `${CONSTANTS.API_HOST}/admin/referral_status`,
    black_lists: `${CONSTANTS.API_HOST}/admin/black_lists`,
    admin_devices: `${CONSTANTS.API_HOST}/admin/devices`,
    activate_device: `${CONSTANTS.API_HOST}/admin/activate_device`,
    reset_free_trial: `${CONSTANTS.API_HOST}/admin/reset_free_trial`,
    withdraw: `${CONSTANTS.API_HOST}/admin/withdraw`,
    app_info: `${CONSTANTS.API_HOST}/admin/app_info`,
    adminServer: `${CONSTANTS.API_HOST}/admin/server`,
    server_default: `${CONSTANTS.API_HOST}/admin/mark_server_as_default`,
    adminEarnings: `${CONSTANTS.API_HOST}/admin/earnings`,
    resellerActivation: `${CONSTANTS.API_HOST}/admin/reseller_activation`,
    payments: `${CONSTANTS.API_HOST}/admin/payments`,
    referral_links: `${CONSTANTS.API_HOST}/admin/referral_links`,
    words: `${CONSTANTS.API_HOST}/admin/words`,
    currencies: `${CONSTANTS.API_HOST}/admin/currencies`,
    user_packages: `${CONSTANTS.API_HOST}/admin/packages`,
    smptConfig: `${CONSTANTS.API_HOST}/admin/smtp`,
    admin_playlist: `${CONSTANTS.API_HOST}/admin/playlist`,
    chart_devices: `${CONSTANTS.API_HOST}/admin/chart/devices`,
    chart_payments: `${CONSTANTS.API_HOST}/admin/chart/payments`,
    get_packajes: `${CONSTANTS.API_HOST}/api/packages`,
    server_state: `${CONSTANTS.API_HOST}/admin/server_state`,
    forgot_password: `${CONSTANTS.API_HOST}/admin/forgot_password`,
    reset_password: `${CONSTANTS.API_HOST}/admin/reset_password`,
    news: `${CONSTANTS.API_HOST}/news`,
    galeries: `${CONSTANTS.API_HOST}/galeries`,
    tickets: `${CONSTANTS.API_HOST}/tickets`,
    tickets_viewed: `${CONSTANTS.API_HOST}/tickets/viewed`,
    tickets_answer: `${CONSTANTS.API_HOST}/tickets/answer`,
    payment_settings: `${CONSTANTS.API_HOST}/admin/payment_settings`,
    edit_payment_settings: `${CONSTANTS.API_HOST}/payment/settings`,
    chat: `${CONSTANTS.API_HOST}/admin/chat`,
    chat_messages: `${CONSTANTS.API_HOST}/admin/chat/messages`,
    view_message: `${CONSTANTS.API_HOST}/admin/chat/messages/view`,
    coupons: `${CONSTANTS.API_HOST}/coupons`,
    activate_coupons: `${CONSTANTS.API_HOST}/activate_coupons`,
    api_app_info: `${CONSTANTS.API_HOST}/api/app_info`,
    change_recommending: `${CONSTANTS.API_HOST}/admin/change_recommending`,
    widgets: `${CONSTANTS.API_HOST}/widgets`,

    activations: `${CONSTANTS.API_HOST}/admin/reseller_activation`,

    IMA_CONFIG: `${CONSTANTS.API_HOST}/ima/admin`,
    LIVES_CHART: `${CONSTANTS.API_HOST}/statistics/top_lives`,
    LIVES: `${CONSTANTS.API_HOST}/statistics/lives`,

    COUNTRIES: `${CONSTANTS.API_HOST}/admin/countries`,

    MOVIES: `${CONSTANTS.API_HOST}/statistics/movies`,
    MOVIES_CHART: `${CONSTANTS.API_HOST}/statistics/top_movies`,

    WEB: {
        TRANSLATIONS: `${CONSTANTS.API_HOST}/editor/get_web_settings`,
        EDIT: `${CONSTANTS.API_HOST}/editor/update_web_settings`,
    },
    REFERRAL:`${CONSTANTS.API_HOST}/admin/referrals`,
    RESELLER:`${CONSTANTS.API_HOST}/admin/reseller`,
    CAPTCHA:`${CONSTANTS.API_HOST}/captcha`,
    CAPTCHA_FOR_LOGIN:`${CONSTANTS.API_HOST}/api/captcha`,
    NOTIFICATION:`${CONSTANTS.API_HOST}/notifications`,
    NOTIFICATION_FREEZE:`${CONSTANTS.API_HOST}/notifications/freeze`,
    XTREAM_UI:`${CONSTANTS.API_HOST}/xuione_config`,
};

export default URLS;
