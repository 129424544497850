import REQUESTS from "../../api/requests";
import { useEffect, useState, useContext } from "react";
import { Table, Modal, DatePicker, Input } from "antd";
import classes from "./index.module.scss";
import MyIcon from "../../components/myicon";
import ICONS from "../../config/icons";
import { useSelector } from "react-redux";
import { selectTranslation } from "../../features/Translation/TranslationSlice";
import TEXT from "../../config/text";
import { CalendarOutlined, SearchOutlined } from "@ant-design/icons";
import { useNavigate, createSearchParams, useSearchParams } from "react-router-dom";
import InitialParams from "../../components/InitialParams";
import getMyDate from "../../components/getMyDate";
import { emptyContext } from "../../context_empty/context_empty";
const { RangePicker } = DatePicker;
const { confirm } = Modal;

function PaymentHistory() {
    const [empty, setEmpty] = useContext(emptyContext);
    const [searchParams] = useSearchParams();
    const [total, setTotal] = useState();
    const [limit, setLimit] = useState(10);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [paymentHistory, setPaymentHistory] = useState([]);
    const [date, setDate] = useState("");
    const [sort, setSort] = useState(["id", "DESC"]);
    const [search, setSearch] = useState({
        comment: "",
    });

    InitialParams(setLimit, setCurrentPage, setSearch, setSort);

    const translation = useSelector(selectTranslation);
    const navigate = useNavigate();
    const goToPosts = (params) =>
        navigate({
            search: `?${createSearchParams(params)}`,
        });

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
            return (
                <Input
                    allowClear
                    autoFocus
                    placeholder="Type text here"
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : []);
                        confirm({ closeDropdown: false });
                    }}
                />
            );
        },
        filterIcon: () => {
            return (
                <>
                    <SearchOutlined />
                </>
            );
        },
    });

    const columns = [
        {
            title: "#",
            dataIndex: "id",
            width: "60px",
            render: (text, record, index) => {
                if (currentPage === 1) {
                    return index + 1;
                } else {
                    return limit * (currentPage - 1) + index + 1;
                }
            },
        },
        {
            title: `${translation["Date"] || TEXT["Date"]}`,
            dataIndex: "createdAt",
            key: "createdAt",
            sorter: true,
            render: (record) => {
                return getMyDate(record);
            },
            filterDropdown: ({}) => (
                <div
                    style={{
                        padding: 8,
                    }}
                >
                    <RangePicker
                        size="small"
                        onChange={(e, dateString) => setDate(dateString)}
                        renderExtraFooter={() => "extra footer"}
                    />
                </div>
            ),
            filterIcon: (filtered) => (
                <CalendarOutlined
                    style={{
                        color: JSON.parse(searchParams.get("between"))?.createdAt
                            ? "#1890ff"
                            : undefined,
                    }}
                />
            ),
        },
        {
            title: `${translation["Payment method"] || TEXT["Payment method"]}`,
            dataIndex: "method",
            key: "method",
            ...getColumnSearchProps(),
        },
        {
            title: `${translation["Amount"] || TEXT["Amount"]}`,
            dataIndex: "amount",
            key: "amount",
            sorter: true,
        },

        {
            title: `${translation["Currency"] || TEXT["Currency"]}`,
            dataIndex: "currency",
            key: "currency",
            ...getColumnSearchProps(),
        },

        {
            title: `${translation["Package name"] || TEXT["Package name"]}`,
            dataIndex: "package_name",
            key: "package_name",
            ...getColumnSearchProps(),
            render: (record, text, index) => {
                if (record) {
                    return record;
                } else {
                    return "N/A";
                }
            },
        },

        {
            title: `${translation["Subscription id"] || TEXT["Subscription id"]}`,
            dataIndex: "subscription_id",
            key: "subscription_id",
            render: (record, text, index) => {
                if (record) {
                    return record;
                } else {
                    return "N/A";
                }
            },
            ...getColumnSearchProps(),
        },

        {
            title: `${translation["Mac"] || TEXT["Mac"]}`,
            dataIndex: "mac",

            key: "mac",
            ...getColumnSearchProps("mac"),
        },

        {
            title: `${translation["Device model"] || TEXT["Device model"]}`,
            dataIndex: "device_model",
            key: "device_model",
            // sorter: true,
            ...getColumnSearchProps(),
            render: (record, text, index) => {
                if (record) {
                    return record;
                } else {
                    return "N/A";
                }
            },
        },
    ];

    const getPaymentHistory = () => {
        setLoading(true);
        const query = {
            page: currentPage,
            limit,
            pagination: 1,
            sort: JSON.stringify(sort),
            search: {},
        };

        if (search.device_model) {
            query.search["device_model"] = search.device_model[0];
        }

        if (search.method) {
            query.search["method"] = search.method[0];
        }

        if (search.currency) {
            query.search["currency"] = search.currency[0];
        }

        if (search.subscription_id) {
            query.search["subscription_id"] = search.subscription_id[0];
        }

        if (search.ip) {
            query.search["ip"] = search.ip[0];
        }

        if (search.mac) {
            query.search["mac"] = search.mac[0];
        }

        if (search.package_name) {
            query.search["package_name"] = search.package_name[0];
        }

        if (search.email) {
            query.search["email"] = search.email[0];
        }

        if (query.search) {
            query.search = JSON.stringify(query.search);
        }

        if (date && date[0]) {
            let to = new Date(date[1]);
            to.setDate(to.getDate() + 1);
            query.between = JSON.stringify({
                createdAt: {
                    from: new Date(date[0]),
                    to: new Date(to),
                },
            });
        }

        goToPosts(query);

        REQUESTS.PAYMENTS.GET(query, (data) => {
            setTotal(data.count);
            // setLimit(data.limit);
            if (data.rows.length === 0 && currentPage > 1) {
                setCurrentPage((current) => current - 1);
            } else {
                setCurrentPage(data.currentPage);
            }
            setLoading(false);
            setPaymentHistory(data.rows);
            if (!data.rows === []) {
                setEmpty(false);
            }
        });
    };

    useEffect(() => {
        return () => {
            setEmpty(true);
        };
    }, []);

    const handleTableChange = (pagination, filters, sorter) => {
        // if (sorter.field) {
        //   setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
        //}
        if (sorter.field) {
            setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
        }
        setSearch(filters);
        if (Object.values(filters)[0]) {
            //   setTitleSearch(Object.values(filters)[0].join(""));
        } else {
            //   setTitleSearch(null);
        }

        setCurrentPage(pagination.current);
        setLimit(pagination.pageSize);
    };

    useEffect(() => {
        let timout = setTimeout(() => {
            getPaymentHistory();
        }, 500);
        return () => {
            clearTimeout(timout);
        };
    }, [search, currentPage, limit, date, sort]);

    return (
        <div className={classes["PaymentHistory_list_table"]}>
            <div className={classes["PaymentHistory_list_title"]}>
                <p className={classes["PaymentHistory_title-text"]}>
                    {translation["Payment History"] || TEXT["Payment History"]}
                </p>
            </div>

            <Table
                loading={loading}
                rowKey="id"
                columns={columns}
                dataSource={paymentHistory}
                onChange={handleTableChange}
                pagination={{
                    position: ["bottomCenter"],
                    current: currentPage,
                    total: total,
                    pageSize: limit,
                    showSizeChanger: true,
                }}
                scroll={{ x: "max-content" }}
                size="small"
            />
        </div>
    );
}

export default PaymentHistory;
