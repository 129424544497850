import classes from "../../style/chatBar.module.scss";
import ChatTabs from "./ChatTab";
import SearchChat from "./SearchChat";

function ChatBar({setIsShow,setInputValue}) {
  return (
    <div className={classes["chatBar_container"]}>
      <SearchChat />
      <ChatTabs setIsShow={setIsShow} setInputValue={setInputValue}/>
    </div>
  );
}

export default ChatBar;
