import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectwidgets } from "../../features/widgets/selector";

import { editActivId, editMeesageCount } from "../../features/chat/chatSlise";
import ChatBar from "./components/chat/ChatBar";
import Messenger from "./components/messenger/Messages";

import classes from "./index.module.scss";

function RealtTimeChat() {
  const [isShow, setIsShow] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [inputValue, setInputValue] = useState("");

  const widgets = useSelector(selectwidgets);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(editActivId("1"));
    dispatch(editMeesageCount(0));
    return () => {
      dispatch(editActivId(""));
    };
  }, []);

  useEffect(() => {
    const handleChangeWindowInnerWidth = () =>
      setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleChangeWindowInnerWidth);
    return () =>
      window.removeEventListener("resize", handleChangeWindowInnerWidth);
  }, []);

  return (
    <div className={classes["real-time_chat-container"]}>
      {((widgets && !widgets.chat) ||
        (widgets &&
          widgets.chat &&
          widgets.chat.enabled &&
          widgets.chat.enabled === "false")) && (
        <>
          <div className="pro-widget-warning-text">
            To use this feature you need to upgrade to Pro Version
            <br />
            Please contact us for more details.
          </div>
          <div className="pro-widget-tab-layer"></div>
        </>
      )}
      <div className={classes["real-time_chat"]}>
        {windowWidth > 600 ? (
          <>
            <ChatBar setIsShow={setIsShow} setInputValue={setInputValue} />
            <Messenger
              isShow={null}
              setIsShow={null}
              inputValue={inputValue}
              setInputValue={setInputValue}
            />
          </>
        ) : isShow ? (
          <Messenger
            isShow={isShow}
            setIsShow={setIsShow}
            inputValue={inputValue}
            setInputValue={setInputValue}
          />
        ) : (
          <ChatBar setIsShow={setIsShow} setInputValue={setInputValue} />
        )}
      </div>
    </div>
  );
}

export default RealtTimeChat;
