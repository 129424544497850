import { useSelector } from "react-redux";

import { selectTranslation } from "../../../features/Translation/TranslationSlice";

import TEXT from "../../../config/text";

import numberChanging from "../../../utils/numberChange";

import classes from "../styles/cardjsx.module.scss";

function CardJsx({ title, total, today, icon,color }) {
  const translation = useSelector(selectTranslation);

  return (
    <div
      className={classes["card_container"]}
      style={{ backgroundColor: color }}
    >
      <div className={classes["titl_block"]}>
        <p className={classes["title_text"]}>{title}</p>
      </div>
      <div className={classes["dashoard_cards_icon_text"]}>
        <div className={classes["dashoard_cards_img"]}>{icon && icon}</div>
        <div className={classes["statistics_block"]}>
          <div className={classes["statistics_block-text_div"]}>
            <p className={classes["statistics_block_text"]}>
              {translation["Total"] || TEXT["Total"].toUpperCase()} :
            </p>
            <p className={classes["statistics_block_index"]}>
              {total && numberChanging(total)}
            </p>
          </div>

          {today != null && (
            <div className={classes["statistics_block-text_div"]}>
              <p className={classes["statistics_block_text"]}>
                {translation["Today"] || TEXT["Today"].toUpperCase()} :
              </p>
              <p className={classes["statistics_block_index"]}>
                {today && numberChanging(today)}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CardJsx;
