import REQUESTS from "../../api/requests";
import { useEffect, useState, useContext } from "react";
import { Table, Button, DatePicker, Input, Modal, notification } from "antd";
import classes from "./index.module.scss";
import TEXT from "../../config/text";
import getMyDate from "../../components/getMyDate";
import {
  CalendarOutlined,
  SearchOutlined,
  ExpandAltOutlined,
  TagOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import DeviceManagerDrawer from "./components/DeviceManagerDrawer";
import { useSelector } from "react-redux";
import { selectTranslation } from "../../features/Translation/TranslationSlice";
import MyIcon from "../../components/myicon";
import ICONS from "../../config/icons";
import showPropsConfirm from "../../components/showPropsConfirm";
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";
import InitialParams from "../../components/InitialParams";
import TableButtons from "../../components/TableButtons";
import ActivationDrawer from "./components/ActivationDrawer";
import { emptyContext } from "../../context_empty/context_empty";

const { RangePicker } = DatePicker;
const { confirm } = Modal;

function DeviceManager() {
  const [empty, setEmpty] = useContext(emptyContext);
  const [searchParams] = useSearchParams();
  const [total, setTotal] = useState(10);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [activationVisible, setActivationVisible] = useState();
  const [deviceManager, setDeviceManager] = useState([]);
  const [editDevice, setEditDevice] = useState(null);
  const [date, setDate] = useState("");
  const [expiredDate, setExpiredDate] = useState("");
  const [activationExpired, setActivationExpired] = useState();
  const [userInfo, setUserInfon] = useState(null);
  const [sort, setSort] = useState(["id", "DESC"]);
  const [search, setSearch] = useState({
    comment: "",
  });

  InitialParams(setLimit, setCurrentPage, setSearch, setSort);

  const navigate = useNavigate();
  const goToPosts = (params) =>
    navigate({
      search: `?${createSearchParams(params)}`,
    });

  const translation = useSelector(selectTranslation);
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
      return (
        <Input
          allowClear
          autoFocus
          placeholder="Type text here"
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
          }}
        />
      );
    },
    filterIcon: () => {
      return <SearchOutlined />;
    },
  });

  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "free_trial":
        showPropsConfirm(
          item.id,
          free_trial,
          loading,
          `${translation["Free trial"] || TEXT["Free trial"]}`,
          "#28857c",
          `${
            translation["Are you sure you want to free trial?"] ||
            TEXT["Are you sure you want to free trial?"]
          }`,
          `${translation["CANCEL"] || TEXT["CANCEL"]}`
        );
        break;
      case "activate":
        setEditDevice(item);
        setActivationVisible(true);
        break;

      case "delete":
        showPropsConfirm(
          item.id,
          deleteTable,
          loading,
          `${translation["DELETE"] || TEXT["DELETE"]}`,
          "#bf4342",
          `${
            translation["Are you sure you want to delete?"] ||
            TEXT["Are you sure you want to delete?"]
          }`,
          `${translation["CANCEL"] || TEXT["CANCEL"]}`
        );
        break;
      default:
        break;
    }
  };

  const deleteTable = (id) => {
    const callback = (data) => {
      getDeviceManager();
    };

    const errorCallback = (data) => {
    };

    REQUESTS.DEVICE.DELETE_DEVICE(id, callback, errorCallback);
  };

  const free_trial = (id) => {
    const body = {
      id: id,
    };
    const callback = (data) => {
      getDeviceManager();
    };

    const errorCallback = (data) => {
    };

    REQUESTS.DEVICE.FREE_TRIAL(body, callback, errorCallback);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => {
        if (currentPage === 1) {
          return index + 1;
        } else {
          return limit * (currentPage - 1) + index + 1;
        }
      },
    },

    {
      title: `${translation["Ip"] || TEXT["Ip"]}`,
      // align: "center",
      dataIndex: "ip",
      key: "ip",
      ...getColumnSearchProps("ip"),
    },

    {
      title: `${translation["Model"] || TEXT["Model"]}`,
      // align: "center",
      dataIndex: "model",
      key: "model",
      ...getColumnSearchProps("model"),
    },

    {
      title: `${translation["Mac"] || TEXT["Mac"]}`,
      // align: "center",
      dataIndex: "mac",
      ...getColumnSearchProps("mac"),
    },

    {
      title: `${translation["Online"] || TEXT["Online"]}`,

      dataIndex: "last_online",
      sorter: true,
      render: (record, iteam, index) => {
        return iteam.online !== false ? (
          <p>{translation["Online"] || TEXT["Online"]}</p>
        ) : iteam.last_online ? (
          getMyDate(iteam.last_online)
        ) : (
          "N/A"
        );
      },
    },
    // {
    //   title: `${translation["Online"] || TEXT["Online"]}`,
    //   // dataIndex: "online",
    //   align: "center",
    //   sorter: true,
    //   render: (text, record, index) => {
    //     return deviceManager[index].online ? (
    //       <MyIcon children={ICONS.CHECK} />
    //     ) : (
    //       <MyIcon children={ICONS.TIMES} />
    //     );
    //   },
    // },
    {
      title: `${translation["user"] || TEXT["User"]}`,
      // align: "center",
      dataIndex: "user",
      key: "user",
      render: (record, text, index) => {
        return record ? record : "No user";
      },
    },
    {
      title: ` ${translation["Created date"] || TEXT["Created date"]}`,
      align: "center",
      dataIndex: "createdAt",
      sorter: true,
      render: (record) => {
        if (record) {
          return getMyDate(record);
        } else {
          return "N/A";
        }
      },
      filterDropdown: ({}) => (
        <div
          style={{
            padding: 8,
          }}
        >
          <RangePicker
            size="small"
            onChange={(e, dateString) => setDate(dateString)}
            renderExtraFooter={() => "extra footer"}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <CalendarOutlined
          style={{
            color: JSON.parse(searchParams.get("between"))?.createdAt
              ? "#1890ff"
              : undefined,
          }}
        />
      ),
    },

    {
      title: ` ${
        translation["Free trial expired"] || TEXT["Free trial expired"]
      }`,
      align: "center",
      dataIndex: "free_trial_expired",
      sorter: true,
      render: (record) => {
        if (record) {
          return getMyDate(record);
        } else {
          return "N/A";
        }
      },
      filterDropdown: ({}) => (
        <div
          style={{
            padding: 8,
          }}
        >
          <RangePicker
            size="small"
            onChange={(e, dateString) => setExpiredDate(dateString)}
            renderExtraFooter={() => "extra footer"}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <CalendarOutlined
          style={{
            color: JSON.parse(searchParams.get("between"))?.free_trial_expired
              ? "#1890ff"
              : undefined,
          }}
        />
      ),
    },

    {
      title: ` ${translation["Expired date"] || TEXT["Expired date"]}`,
      align: "center",
      dataIndex: "activation_expired",
      sorter: true,
      render: (record) => {
        if (record) {
          return getMyDate(record);
        } else {
          return "N/A";
        }
      },
      filterDropdown: ({}) => (
        <div
          style={{
            padding: 8,
          }}
        >
          <RangePicker
            size="small"
            onChange={(e, dateString) => setActivationExpired(dateString)}
            renderExtraFooter={() => "extra footer"}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <CalendarOutlined
          style={{
            color: JSON.parse(searchParams.get("between"))?.activation_expired
              ? "#1890ff"
              : undefined,
          }}
        />
      ),
    },
    {
      title: "",
      width: "60px",
      dataIndex: "action",
      align: "center",
      render: (text, record, index) => (
        <div onClick={(e) => e.stopPropagation()}>
          <TableButtons
            handleMenuClick={(e) => handleMenuClick(e, record)}
            buttons={[
              {
                key: "free_trial",
                text: `${translation["Free trial"] || TEXT["Free trial"]}`,
                icon: <ExpandAltOutlined />,
                disabled: record.free_trial ? true : false,
              },

              {
                key: "activate",
                text: `${translation["Activate"] || TEXT["Activate"]}`,
                icon: <TagOutlined />,
              },
              {
                key: "delete",
                text: `${translation["Delete"] || TEXT["Delete"]}`,
                icon: <DeleteOutlined />,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  const getDeviceManager = () => {
    setLoading(true);
    const query = {
      page: currentPage,
      limit,
      pagination: 1,
      sort: JSON.stringify(sort),
      search: {},
    };

    if (search.user) {
      query.search["user"] = search.user[0];
    }

    if (search.code) {
      query.search["code"] = search.code[0];
    }

    if (search.mac) {
      query.search["mac"] = search.mac[0];
    }

    if (search.model) {
      query.search["model"] = search.model[0];
    }

    if (search.ip) {
      query.search["ip"] = search.ip[0];
    }

    if (query.search) {
      query.search = JSON.stringify(query.search);
    }

    let dataArray = [
      { date: date, betweenDate: "createdAt" },
      { date: expiredDate, betweenDate: "free_trial_expired" },

      { date: activationExpired, betweenDate: "activation_expired" },
    ];

    for (let i = 0; i < dataArray.length; i++) {
      if (dataArray[i].date && dataArray[i].date[0]) {
        let to = new Date(dataArray[i].date[1]);
        to.setDate(to.getDate() + 1);
        if (query.between) {
          query.between = JSON.stringify({
            ...JSON.parse(query?.between),
            [dataArray[i].betweenDate]: {
              from: new Date(dataArray[i].date[0]),
              to: new Date(to),
            },
          });
        } else {
          query.between = JSON.stringify({
            [dataArray[i].betweenDate]: {
              from: new Date(dataArray[i].date[0]),
              to: new Date(to),
            },
          });
        }
      }
    }

    goToPosts(query);

    REQUESTS.DEVICE.GET(query, (data) => {
      setTotal(data.count);
      // setLimit(data.limit);
      if (data.rows.length === 0 && currentPage > 1) {
        setCurrentPage((current) => current - 1);
      } else {
        setCurrentPage(data.currentPage);
      }
      setLoading(false);

      setDeviceManager(data.rows);

      if (!data.rows == []) {
        setEmpty(false);
      }
    });
  };

  useEffect(() => {
    return () => {
      setEmpty(true);
    };
  }, []);

  const handleTableChange = (pagination, filters, sorter) => {
    // if (sorter.field) {
    //   //   setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    // }

    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
    setSearch(filters);

    if (Object.values(filters)[0]) {
      //   setTitleSearch(Object.values(filters)[0].join(""));
    } else {
      //   setTitleSearch(null);
    }

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  const closeDrawer = () => {
    setVisible(false);
  };

  const closeDActivationrawer = () => {
    setActivationVisible(false);
  };

  useEffect(() => {
    let timout = setTimeout(() => {
      getDeviceManager();
    }, 500);
    return () => {
      clearTimeout(timout);
    };
  }, [search, currentPage, limit, date, sort, expiredDate, activationExpired]);

  return (
    <div className={classes["devices_table"]}>
      <div className={classes["devices_table_head"]}>
        <p className={classes["devices_head_text"]}>
          {/* <DesktopOutlined />  */}
          {translation["Devices"] || TEXT["Devices"]}
        </p>
      </div>

      <Table
        onRow={(record, rowIndex) => {
          return {
            onClick: (e) => {
              setUserInfon(record);
              setVisible(true);
            },
          };
        }}
        loading={loading}
        rowKey="id"
        columns={columns}
        dataSource={deviceManager}
        rowClassName={classes["deviceManager_row"]}
        onChange={handleTableChange}
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true,
          // defaultPageSize: 1,
        }}
        size="small"
        scroll={{ x: "max-content" }}
      />

      <DeviceManagerDrawer
        visible={visible}
        onClose={closeDrawer}
        userInfo={userInfo}
      />

      <ActivationDrawer
        visible={activationVisible}
        onClose={closeDActivationrawer}
        current={editDevice}
        getData={getDeviceManager}
        translation={translation}
      />
    </div>
  );
}

export default DeviceManager;
