import { useState, useEffect } from "react";
import {
  get_chat_list,
  view_message_status,
} from "../../requests/realTimechatRequests";
import { useDispatch, useSelector } from "react-redux";
import {
  selectChatList,
  editChatList,
  selectchat,
  selectActivId,
} from "../../../../features/chat/chatSlise";
import RenderChatLis from "./renderChatList";
import styles from "../../style/chatList.module.scss";

function ChatList({ activeKey, setIsShow, setInputValue }) {
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(10);

  const chat = useSelector(selectchat);
  const user = useSelector(selectActivId);
  const chatList = useSelector(selectChatList);
  const dispatch = useDispatch();

  const get_chat = () => {
    const getList = (data) => {
      dispatch(editChatList(data));
    };

    const query = {
      limit,
    };

    if (activeKey === "New") {
      query.filter = JSON.stringify({
        viewed: false,
        sender: "user",
      });
    } else if (activeKey === "Unanswered") {
      query.filter = JSON.stringify({
        viewed: true,
        sender: "user",
      });
    }
    get_chat_list(getList, query, setCount);
  };

  useEffect(() => {
    get_chat();
  }, [chat, activeKey]);

  useEffect(() => {
    if (user?.id) {
      view_message_status({ chat_id: user?.id }, get_chat);
    }
  }, [user]);

  useEffect(() => {
    get_chat();
  }, [limit]);

  useEffect(() => {
    function handleScroll() {
      const windowHeight = window.innerHeight;
      const height = document.documentElement.scrollHeight;
      const top = document.documentElement.scrollTop;

      if (windowHeight + top + 1 >= height) {
        setLimit(count);
      }
    }

    window.addEventListener("scroll", handleScroll, true);

    return () => window.removeEventListener("scroll", handleScroll, true);
  }, [count]);

  return (
    <div className={styles["chat-list-wrapper"]}>
      {RenderChatLis(chatList, setIsShow, setInputValue)}
    </div>
  );
}

export default ChatList;
