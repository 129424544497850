import axios from "axios";

const request = (method, url, body, callback, errorCallback, notTimeout) => {
  let myAxios = axios.create();

  myAxios.defaults.headers.common["Authorization"] =
    localStorage.getItem("TOKEN");

  myAxios[method](url, method === "delete" ? { data: body } : body)
    .then((response) => {
      if (response.data.error) {
        if (response.data.message === "Forbiden") {
          window.location.href = "#";
        }

        if (errorCallback) {
          errorCallback(response.data.message);
        }
      } else {
        callback(response.data.message);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error.response.data.message);
      }
    });
};

export default request;
