export const chatTabData = [
  {
    id: 1,
    tabe: "All",
    key: "All",
  },
  {
    id: 2,
    tabe: "New",
    key: "New",
  },

  {
    id: 3,
    tabe: "Unanswered",
    key: "Unanswered",
  },
];
