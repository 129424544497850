import { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import { Drawer } from "antd";

import getMyDate from "../../../components/getMyDate";
import DevicePlaylists from "./DevicePlaylists";

import { selectTranslation } from "../../../features/Translation/TranslationSlice";

import TEXT from "../../../config/text";

import classes from "../style/deviceManagerDraver.module.scss";


const DeviceManagerDrawer = ({ onClose, visible, userInfo }) => {
  const translation = useSelector(selectTranslation);
  const [modelImgISrc, setModelImgISrc] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  function getModelImg() {
    if (userInfo?.model === "webos") {
      setModelImgISrc("assets/img/tizencreate.jpeg");
    } else if (userInfo?.model === "tizen") {
      setModelImgISrc("assets/img/Tizen_logo.png");
    } else if (userInfo?.model === "android") {
      setModelImgISrc("assets/img/Android_Logo.png");
    }
  }

  useEffect(() => {
    getModelImg();
  }, [userInfo]);

  useEffect(() => {
    const handleChangeWindowInnerWidth = () =>
      setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleChangeWindowInnerWidth);
    return () =>
      window.removeEventListener("resize", handleChangeWindowInnerWidth);
  },[]);

  return (
    <Drawer
      title={`Device info`}
      placement="right"
      onClose={onClose}
      visible={visible}
      width={windowWidth>880 ? 800 : "83vw"}
    >
      <div className={classes["userInfo_container"]}>
        <div className={classes["userInfo_div"]}>
          <img width={windowWidth>880 ? 90 : 50} src={modelImgISrc} alt="" />
        </div>
        <div className={classes["userInfo_div"]}>
          <ul className={classes["userInfo_list"]}>
            <li className={classes["user-info_item"]}>
              {" "}
              {translation["Model"] || TEXT["Model"]}: {userInfo?.model}
            </li>
            <li className={classes["user-info_item"]}>
              {" "}
              {translation["Online"] || TEXT["Online"]}:{" "}
              {userInfo?.online ? "YES" : "NO"}
            </li>
            <li className={classes["user-info_item"]}>
              {" "}
              {translation["Payed"] || TEXT["Payed"]}:{" "}
              {userInfo?.payed ? "YES" : "NO"}
            </li>
            <li className={classes["user-info_item"]}>
              {" "}
              {translation["Last online"] || TEXT["Last online"]}:{" "}
              {getMyDate(userInfo?.last_online)}
            </li>
            <li className={classes["user-info_item"]}>
              {" "}
              {translation["Device Created date"] ||
                TEXT["Device Created date"]}
              : {getMyDate(userInfo?.createdAt)}
            </li>
            <li className={classes["user-info_item"]}>
            {translation["Mac"] || TEXT["Mac"]}: {userInfo?.mac}
          </li>
          <li className={classes["user-info_item"]}>
            {translation["Email"] || TEXT["Email"]}:{" "}
            {userInfo?.reseller?.email ? userInfo?.reseller?.email : "N/A"}
          </li>
          </ul>
        </div>
      </div>
      <DevicePlaylists userInfo={userInfo} />
    </Drawer>
  );
};

export default DeviceManagerDrawer;
