import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Select } from "antd";

import REQUESTS from "../../../../api/requests";

import classes from "../../../../layout/style/languageBlock.module.scss";

export default function Language({ onChange }) {
    const location = useLocation();
    const [languages, setLanguages] = useState([]);
    const [selected, setSelected] = useState("");

    const getLanguages = () => {
        let query = {
            filter: JSON.stringify({ is_active: true }),
        };

        function callback(data) {
            let languageId = localStorage.getItem("langId");

            let isDefault = data?.rows[0];

            setLanguages(data?.rows);

            if (languageId) {
                isDefault = data.rows.find((el) => el.id == languageId);
            }

            setSelected(isDefault.id);
        }

        REQUESTS.LANGUAGES(query, callback);
    };

    const handleChange = (value) => {
        const finder = languages.find((el) => el.id == value);
        if (finder) {
            onChange(finder.iso_code);
            setSelected(value);
        }
    };

    useEffect(() => {
        getLanguages();
    }, [location]);

    return (
        <div
            className="language-block"
            style={{
                marginBottom: 15,
                width: 50,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Select
                bordered={false}
                className={classes["select"]}
                dropdownClassName={classes["dropdown"]}
                suffixIcon={null}
                onChange={handleChange}
                value={selected}
                listHeight={135}
            >
                {languages?.map((el) => {
                    return (
                        <Select.Option key={el.id} value={el.id}>
                            <img src={el?.icon} alt="" />
                            <span className={classes["iso-code"]}>{el.iso_code.toUpperCase()}</span>
                        </Select.Option>
                    );
                })}
            </Select>
        </div>
    );
}
