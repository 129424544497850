import { useState, useEffect, useRef } from "react";

import TEXT from "../../../config/text";
import REQUESTS from "../../../api/requests";
import classes from "../style/couponsDrawer.module.scss";
import ErrorMessage from "../../../components/ErrorMessage";

import { Button, Form, Input, Drawer, Select } from "antd";
const { Option } = Select;
const { TextArea } = Input;

const CouponsDrawer = ({
  onClose,
  visible,
  current,
  getData,
  translation,
  followAddElement,
  setCsv,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [recommended, setRecommended] = useState(current?.recommended || false);
  const useScrollRef = useRef();
  const useParentRef = useRef();
  const [message, setMessage] = useState({
    type: false,
    text: "",
  });

  const [couponsType, setCouponsType] = useState("monthly");

  const onValuesChange = (e) => {
    setMessage({
      type: false,
      text: "",
    });
  };

  useEffect(() => {
    if (visible === false) {
      setLoading(false);
      setRecommended(false);
      onValuesChange();
      setRecommended(false);
    }
  }, [visible]);

  useEffect(() => {
    if (visible === true) {
      scrollIntoView();
    }
  }, [visible]);

  useEffect(() => {
    if (current) {
      setRecommended(current.recommended);
    }
  }, [current]);

  function scrollIntoView() {
    setTimeout(() => {
      useScrollRef.current.scrollIntoView({ block: "end", behavior: "smooth" });
    }, 200);
  }

  const onFinish = (values) => {
    setLoading(true);

    const body = {
      count: Number(values.count),
      type: couponsType,
      comment: values.comment,
    };

    function callback(data) {
      if (!current) {
        followAddElement.setCurrentPage(1);
        followAddElement.setSearch("");
        followAddElement.setSort(["id", "DESC"]);
        followAddElement.setCurrentPage(1);
        followAddElement.setLimit(10);
        followAddElement.setTotal();
        followAddElement.setDate("");
      }
      setLoading(false);
      onClose();
      getData();
      setCsv(data);
    }

    function errorCallback(err) {
      setLoading(false);
      setMessage({ type: false, text: err });
    }

    REQUESTS.COUPONS.POST(body, callback, errorCallback);
  };

  useEffect(() => {
    if (current) {
      form.setFields([
        {
          name: "count",
          value: current.count,
        },

        {
          name: "type",
          value: current.type,
        },

        {
          name: "commrnt",
          value: current.comment,
        },
      ]);
    } else {
      form.resetFields();
    }
  }, [visible]);

  const handleChange = (value) => {
    setCouponsType(value);
  };

  return (
    <div ref={useParentRef}>
      <Drawer
        title={`${translation["Add"] || TEXT["Add"]} ${
          translation["GIFT Code"] || TEXT["GIFT Code"]
        }`}
        placement="right"
        onClose={onClose}
        open={visible}
      >
        <div ref={useScrollRef}></div>
        <Form
          form={form}
          name="profile"
          layout="vertical"
          onFinish={onFinish}
          onValuesChange={onValuesChange}
          initialValues={{
            password: "",
          }}
        >
          <Form.Item
            label={translation["Count"] || TEXT["Count"]}
            name="count"
            rules={[
              {
                required: true,
                message: "Please input count",
              },
            ]}
          >
            <Input type={"number"} min="0" />
          </Form.Item>

          <Form.Item
            label={translation["Type"] || TEXT["Type"]}
            name="type"
            rules={[
              {
                required: !couponsType,
                message: "Please input type",
              },
            ]}
          >
            <Select
              className={classes["form_item_select"]}
              value={couponsType}
              defaultValue={couponsType}
              onChange={handleChange}
            >
              <Option value="monthly">monthly</Option>
              <Option value="yearly">yearly</Option>
              <Option value="lifetime">lifetime</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={translation["Comment"] || TEXT["Comment"]}
            name="comment"
          >
            <TextArea rows={4} />
          </Form.Item>

          <ErrorMessage message={message} />
          <div className={classes["save_button_div"]}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className={classes["save_button"]}
                loading={loading}
              >
                {translation["Save"] || TEXT["Save"]}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Drawer>
    </div>
  );
};

export default CouponsDrawer;
