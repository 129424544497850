import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { selectwidgets } from "../../../features/widgets/selector";

import { Button, notification, Transfer } from "antd";

import REQUESTS from "../../../api/requests";

import "../style/geoRestriction.scss";

export default function GEORestriction() {
  const [countries, setCountries] = useState([]);
  const [ids, setIds] = useState([]);
  const [isDisable, setIsDisable] = useState(false);
  const [targetKeys, setTargetKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  const widgets = useSelector(selectwidgets);

  const getCountries = () => {
    REQUESTS.COUNTRIES.GET(
      (data) => {
        const dataSource = data.rows.map((item) => ({
          key: item.id,
          title: item.name,
        }));

        const restrictedCountries = data.rows
          .filter((item) => item.is_disable)
          .map((item) => item.id);

        setTargetKeys(restrictedCountries);
        setCountries(dataSource);
      },
      (err) => {}
    );
  };

  const update = () => {
    setLoading(true);

    const body = {
      ids,
      is_disable: isDisable,
    };

    function callback(message) {
      setLoading(false);

      getCountries();
      notification.open({
        message: "GEO Restriction Settings",
        description: message,
        duration: 5,
      });
    }

    function errorCallback(err) {
      setLoading(false);

      notification.error({
        message: "GEO Restriction Settings",
        description: err,
        duration: 5,
      });
    }

    REQUESTS.COUNTRIES.PUT(body, callback, errorCallback);
  };

  const onChange = (nextTargetKeys, direction, moveKeys) => {
    if (direction === "right") {
      setIsDisable(true);
      setIds(nextTargetKeys);
    } else {
      setIsDisable(false);
      setIds(moveKeys);
    }

    setTargetKeys(nextTargetKeys);
  };

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <>
      {((widgets && !widgets.geo_restriction) ||
        (widgets.geo_restriction &&
          widgets.geo_restriction.enabled &&
          widgets.geo_restriction.enabled === "false")) && (
        <>
          <div className="pro-widget-warning-text">
            To use this feature you need to upgrade to Pro Version
            <br />
            Please contact us for more details.
          </div>
          <div className="pro-widget-tab-layer"></div>
        </>
      )}
      <div className="geo-restriction-setion">
        <h3 className="geo-restriction-setion_title">
          GEO Restriction Settings
        </h3>
          <Transfer
          className="geo-restriction-setion_transfer"
          dataSource={countries}
          titles={["All countries", "Restricted countries"]}
          targetKeys={targetKeys}
          onChange={onChange}
          render={(item) => item.title}
        />
        <div className="geo-restriction-setion_btn-container">
          <Button type="primary" loading={loading} onClick={update}>
            Save
          </Button>
        </div>
      </div>
    </>
  );
}
