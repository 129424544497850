import { useState, useEffect } from "react";

import TEXT from "../../../config/text";
import REQUESTS from "../../../api/requests";
import classes from '../style/createplaylist.module.scss'
import ErrorMessage from "../../../components/ErrorMessage";
import { Button, Form, Input,  Drawer } from "antd";
import {
    SafetyCertificateOutlined,
} from "@ant-design/icons";



const Createplaylist = ({ onClose, visible, current, getData , translation,userInfo}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({
        type: false,
        text: "",
    });

    const onValuesChange = (e) => {
        setMessage({
            type: false,
            text: "",
        });
    };


    useEffect(()=>{
        if (visible===false) {
            setLoading(false)
            onValuesChange()
        }
        },[visible])

    const onFinish = (values) => {
        setLoading(true);

        let body

         if (current) {
            body = {
                    name: values.name,
                    url: values.M3U8_url,
                    id:current.id    
                }
         }else{
            body = {
                name: values.name,
                url: values.M3U8_url,
                deviceId:userInfo.id     
            }
         }

        // const body = {
        //     name: values.name,
        //     url: values.M3U8_url,
        //     id:current?current.id:userInfo.id     
        // }

        function callback() {
            setLoading(false);
            onClose();
            getData();
        }

        function errorCallback(err) {
            setLoading(false);
            setMessage({ type: false, text: err });
        }
            if (current) {
                REQUESTS.DEVICE.EDIT_PLAYLIST(body, callback, errorCallback);
            } else {
                REQUESTS.DEVICE.ADD(body, callback, errorCallback);
            }
    };


    useEffect(() => {
        if (current) {
            form.setFields([
                {
                    name: "name",
                    value: current.name,
                },

                {
                    name: "M3U8_url",
                    value: current.url,
                },         
               
            ]);
        } else {
            form.resetFields();
        }
    }, [current, visible]);

    return (
        <Drawer
            title={translation["Add Playlist"] || TEXT["Add Playlist"]}
            placement="right"
            onClose={onClose}
            open={visible}
        >
            <Form
                form={form}
                name="profile"
                layout="vertical"
                onFinish={onFinish}
                onValuesChange={onValuesChange}
                initialValues={{
                    password: "",
                }}
            >
                <Form.Item
        label={translation["Name"] || TEXT["Name"]}
        name="name"
        rules={[
          {
            required: true,
            message: "Please input name",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={translation["M3U8 URL"] || TEXT["M3U8 URL"]}
        name="M3U8_url"
        rules={[
          {
            required: true,
            message: "Please input M3U8 url",
          },
        ]}
      >
        <Input />
      </Form.Item>
                
              
                          
                <ErrorMessage message={message} />
               <div className={classes["playlist_save_div"]}>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className={classes["playlist_save"]}
                        loading={loading}
                    >
                      {translation["Save"] || TEXT["Save"]}
                    </Button>
                </Form.Item>
            </div>
            </Form>
        </Drawer>
    );
};

export default Createplaylist;