import React, { useEffect, useState } from "react";

import { DatePicker } from "antd";

import { BarChart, Bar, XAxis, YAxis, Cell } from "recharts";

import REQUESTS from "../../../api/requests";

import styles from "../styles/mostUsedDevices.module.scss";

import "../styles/chartStyle.scss";

const UsedDevicesProgres = () => {
  const [chartData, setChartData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const getChart = () => {
    const query = { year: selectedYear || new Date().getFullYear()};
    function collback(data) {
      const newData = [
        {
          name: "Jan",
        },
        {
          name: "Feb",
        },
        {
          name: "Mar",
        },
        {
          name: "Apr",
        },
        {
          name: "May",
        },
        {
          name: "Jun",
        },
        {
          name: "Jul",
        },
        {
          name: "Aug",
        },
        {
          name: "Sep",
        },
        {
          name: "Oct",
        },
        {
          name: "Nov",
        },
        {
          name: "Dec",
        },
      ];

      for (let j = 0; j < newData.length; j++) {
        for (let i = 0; i < data.length; i++) {
          if (i == j) {
            newData[j]["pv"] = data[i];
            newData[j]["uv"] = i + 1;
          }
        }
      }
      setChartData(newData);
    }

    function errorCollback(data) {}

    REQUESTS.STATISTICS.CART_DEVICES(collback, errorCollback, query);
  };

  const onChange = (date, dateString) => {
    setSelectedYear(dateString);
  };

  useEffect(() => {
    getChart();
  }, [selectedYear]);

  return (
    <div className="most-used-devices-BarChart">
      <div className={styles["most-used-devices-chart-container"]}>
        <div className={styles["title-datepicker-container"]}>
          <span className={styles["title"]}>DEVICE</span>
          <DatePicker onChange={onChange} picker="year"   placeholder="Select date"/>
        </div>
        <BarChart
          width={700}
          height={250}
          data={chartData}
          margin={{ top: 0, right: 30, left: 20, bottom: 5 }}
          barSize={17}
        >
          <XAxis dataKey="name"></XAxis>
          <YAxis />
          <Bar dataKey="pv">
            {chartData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  [
                    "#4A77F7",
                    "#C2D4FD",
                    "#4ED395",
                    "#C3F1DD",
                    "#4B5C7F",
                    "#C3CAD6",
                    "#F3B115",
                    "#FBE7AB",
                    "#5B3FF7",
                    "#C9C0FD",
                    "#5DBCE7",
                    "#CAE9F7",
                  ][index]
                }
              />
            ))}
          </Bar>
        </BarChart>
      </div>
    </div>
  );
};

export default UsedDevicesProgres;
