import { useState } from "react";

import { useSelector } from "react-redux";

import { Tabs } from "antd";

import { selectTranslation } from "../../../../features/Translation/TranslationSlice";
import TEXT from "../../../../config/text";

import ChatList from "./ChatList";
import { chatTabData } from "./chatData";

import classes from "../../style/chatTab.module.scss";

const ChatTabs = ({ setIsShow, setInputValue }) => {
  const translation = useSelector(selectTranslation);
  const [activeKey, setActiveKey] = useState("All");

  return (
    <div className={classes["tabs_container"]}>
      <div className={classes["chat-tabs-top"]}>
        <Tabs
          defaultActiveKey="All"
          onTabClick={(e) => {
            setActiveKey(e);
          }}
        >
          {chatTabData.map((el) => {
            return (
              <Tabs.TabPane
                tab={
                  <span
                    className={
                      classes[
                        activeKey === `${el.key}`
                          ? "tab_title-active"
                          : "tab_title"
                      ]
                    }
                  >
                    {translation[`${el.tabe}`] || TEXT[`${el.tabe}`]}
                  </span>
                }
                key={`${el.key}`}
              ></Tabs.TabPane>
            );
          })}
        </Tabs>
      </div>
      <div className={classes["chat-tabs-content"]}>
        <ChatList
          activeKey={activeKey}
          setIsShow={setIsShow}
          setInputValue={setInputValue}
        />
      </div>
    </div>
  );
};

export default ChatTabs;
