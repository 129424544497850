import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { selectTranslation } from "../../../features/Translation/TranslationSlice";

import REQUESTS from "../../../api/requests";
import TEXT from "../../../config/text";

import CardJsx from "./Card";
import classes from "../styles/dashboard.module.scss";

function RenderTotalsCards() {
  const [totals, setTotals] = useState({});
  const [onlineCliants, setOnlineCliants] = useState({});
  const translation = useSelector(selectTranslation);

  const getTotals = () => {
    REQUESTS.STATISTICS.TOTALS(setTotals);
  };

  const getOnline = () => {
    REQUESTS.STATISTICS.ONLINE_CLIENTS(setOnlineCliants);
  };

  useEffect(() => {
    getTotals();
    getOnline();
  }, []);

  const playlist =
    translation["Playlist"] && translation["Playlist"].toUpperCase();
  const payments =
    translation["Payments"] && translation["Payments"].toUpperCase();
  const online = translation["Online"] && translation["Online"].toUpperCase();

  return (
    <>
      <div className={classes["dashoard_cards_container"]}>
        <div className={classes["dashoard_cards"]}>
          <CardJsx
            title={playlist || TEXT["Playlist"].toUpperCase()}
            total={totals.total_playlists}
            today={totals.today_playlists}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 36 48"
              >
                <path
                  id="Icon_awesome-clipboard-list"
                  data-name="Icon awesome-clipboard-list"
                  d="M31.5,6H24A6,6,0,0,0,12,6H4.5A4.5,4.5,0,0,0,0,10.5v33A4.5,4.5,0,0,0,4.5,48h27A4.5,4.5,0,0,0,36,43.5v-33A4.5,4.5,0,0,0,31.5,6ZM9,39.75a2.25,2.25,0,1,1,2.25-2.25A2.245,2.245,0,0,1,9,39.75Zm0-9a2.25,2.25,0,1,1,2.25-2.25A2.245,2.245,0,0,1,9,30.75Zm0-9a2.25,2.25,0,1,1,2.25-2.25A2.245,2.245,0,0,1,9,21.75Zm9-18A2.25,2.25,0,1,1,15.75,6,2.245,2.245,0,0,1,18,3.75Zm12,34.5a.752.752,0,0,1-.75.75H15.75a.752.752,0,0,1-.75-.75v-1.5a.752.752,0,0,1,.75-.75h13.5a.752.752,0,0,1,.75.75Zm0-9a.752.752,0,0,1-.75.75H15.75a.752.752,0,0,1-.75-.75v-1.5a.752.752,0,0,1,.75-.75h13.5a.752.752,0,0,1,.75.75Zm0-9a.752.752,0,0,1-.75.75H15.75a.752.752,0,0,1-.75-.75v-1.5a.752.752,0,0,1,.75-.75h13.5a.752.752,0,0,1,.75.75Z"
                  fill="#FBB142"
                />
              </svg>
            }
            color={"#FBB142"}
          />

          <CardJsx
            title={payments || TEXT["Payments"].toUpperCase()}
            total={totals.total_payments}
            today={totals.today_payments}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 48 38"
              >
                <path
                  id="Icon_material-payment"
                  data-name="Icon material-payment"
                  d="M46.2,6H7.8a4.738,4.738,0,0,0-4.776,4.75L3,39.25A4.759,4.759,0,0,0,7.8,44H46.2A4.759,4.759,0,0,0,51,39.25V10.75A4.759,4.759,0,0,0,46.2,6Zm0,33.25H7.8V25H46.2Zm0-23.75H7.8V10.75H46.2Z"
                  transform="translate(-3 -6)"
                  fill="#4FDFB1"
                />
              </svg>
            }
            color={"#4FDFB1"}
          />

          <CardJsx
            title={online || TEXT["Online"].toUpperCase()}
            total={onlineCliants.total_clients || 0}
            today={onlineCliants.today_clients || 0}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="#ED5B75"
                viewBox="0 0 48 48"
              >
                <g
                  id="Group_11"
                  data-name="Group 11"
                  transform="translate(-1812 -300)"
                >
                  <rect
                    id="Rectangle_25"
                    data-name="Rectangle 25"
                    width="8"
                    height="8"
                    rx="2"
                    transform="translate(1812 340)"
                    fill="#ED5B75"
                  />
                  <rect
                    id="Rectangle_26"
                    data-name="Rectangle 26"
                    width="8"
                    height="18"
                    rx="2"
                    transform="translate(1822 330)"
                    fill="#ED5B75"
                  />
                  <rect
                    id="Rectangle_27"
                    data-name="Rectangle 27"
                    width="8"
                    height="28"
                    rx="2"
                    transform="translate(1832 320)"
                    fill="#ED5B75"
                  />
                  <rect
                    id="Rectangle_28"
                    data-name="Rectangle 28"
                    width="8"
                    height="38"
                    rx="2"
                    transform="translate(1842 310)"
                    fill="#ED5B75"
                  />
                  <rect
                    id="Rectangle_29"
                    data-name="Rectangle 29"
                    width="8"
                    height="48"
                    rx="2"
                    transform="translate(1852 300)"
                    fill="#ED5B75"
                  />
                </g>
              </svg>
            }
            color={"#ED5B75"}
          />
        </div>
      </div>
    </>
  );
}

export default RenderTotalsCards;
