const CONSTANTS = {
    JWT: process.env.REACT_APP_JWT || "JWT",
    REMEMBER_ME: process.env.REACT_APP_REMEMBER_ME || "REMEMBER_ME",
    APP_NAME: process.env.REACT_APP_APPLICATION_NAME || "XPLAYER ADMIN",
    API_HOST: process.env.REACT_APP_API_URL || "http://api.jaroiptv.com",
    APP_COLOR: process.env.REACT_APP_COLOR || "black",
    ACTIVE_COLOR: process.env.REACT_APP_ACTIVE_COLOR || "green",
    ACTIVE_BACKGROUND: process.env.REACT_APP_ACTIVE_BACKGROUND || "white",
    LINK_COLOR: process.env.REACT_APP_LINK_COLOR || "white",
    LOGO_SIZE: process.env.REACT_APP_LOGO_SIZE || "50px",
    UPDATED_VERSION: "v1.0.5",
    API_UPDATED_VERSION: "v1.0.2",
};

export default CONSTANTS;
