import { Input } from "antd";

export default function TranslationInput({ label, value, onChange, name }) {
    return (
        <div style={{ marginBottom: 10 }}>
            <div style={{ color: "gray", marginBottom: 5 }}>{label}</div>
            <Input.TextArea
                rows={1}
                name={name}
                value={value}
                onChange={onChange}
                placeholder="Enter text"
            />
        </div>
    );
}
