import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Form, Input, Drawer, Button } from "antd";

import { selectTranslation } from "../../../features/Translation/TranslationSlice";
import TEXT from "../../../config/text";
import REQUESTS from "../../../api/requests";

import ErrorMessage from "../../../components/ErrorMessage";
import "../reseller.scss";

export default function ActivationDrawer({
  visible,
  setAddActivationDrawer,
  getResellers,
  selectedItem,
  setSelectedItem,
  activationType,
}) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    type: false,
    text: "",
  });
  const [form] = Form.useForm();

  const translation = useSelector(selectTranslation);

  const onClose = () => {
    setAddActivationDrawer(false);
    form.resetFields();
  };

  function onFinish(values) {
    setLoading(false);

    if (activationType === "Add activations") {
      REQUESTS.RESELLER_ACTIVATIONS.POST(
        {
          reseller_id: selectedItem?.id,
          count: +values.count,
        },
        () => {
          getResellers();
          onClose();
          setSelectedItem(null);
        },
        (err) => setMessage({ type: false, text: err })
      );
    } else {
      REQUESTS.RESELLER_ACTIVATIONS.PUT(
        {
          reseller_id: selectedItem?.id,
          count: +values.count,
        },
        () => {
          getResellers();
          onClose();
          setSelectedItem(null);
        },
        (err) => setMessage({ type: false, text: err })
      );
    }
      
  }

  function onFinishFailed() {
    setLoading(false);
  }

  const onValuesChange = () => {
    setMessage({
      text: "",
      type: true,
    });
  };

  const onSubmit = () => {
    setLoading(true);
    setMessage({
      text: "",
      type: true,
    });
    form.submit();
  };

  useEffect(() => {
    if (!visible) {
      setMessage({
        text: "",
        type: true,
      });
    }
  }, [visible]);

  useEffect(() => {
    if (activationType ==="Take activations") {
      form.setFieldsValue({
        count: selectedItem?.total_activations,
      });
    } else {
      form.setFieldsValue({
        count: "",
      });
    }
  }, [activationType, selectedItem]);
  
  return (
    <Drawer
      title={
        activationType === "Add activations"
          ? translation["Add activations"] || TEXT["Add activations"]
          : translation["Take activations"] || TEXT["Take activations"]
      }
      placement="right"
      onClose={onClose}
      open={visible}
      width={560}
    >
      <Form
        form={form}
        name="Add activations"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onValuesChange}
      >
        <div>
          <Form.Item
            label={translation["Count"] || TEXT["Count"]}
            name="count"
            rules={[
              {
                required: true,
                message: "Please input count",
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <ErrorMessage message={message} />
          <div className="activation-drawer">
            <Form.Item>
              <Button
                type="primary"
                loading={loading}
                style={{
                  width: 200,
                }}
                onClick={onSubmit}
              >
                {translation["Save"] || TEXT["Save"]}
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </Drawer>
  );
}
